import { createSlice } from "@reduxjs/toolkit";
import { getStudentDetails } from "services/student/student";

const initialState = {
    student : {
        data: [],
        isLoading: false,
        isSuccess: false,
        errorMessage: ''
    }
}

export const getStudentDetailsSlice = createSlice({
    name: 'student',
    initialState,
    extraReducers: {
        [getStudentDetails.pending]: (state) => {
            state.student.isLoading = true;
        },
        [getStudentDetails.fulfilled]: (state, { payload }) => {
            state.student.isLoading = false;
            state.student.isSuccess = true;
            state.student.data = payload;
        },
        [getStudentDetails.rejected]: (state, { payload }) => {
            state.student.isLoading = false;
            state.student.isSuccess = false;
            state.user.errorMessage = payload
        }
    }
})

export default getStudentDetailsSlice.reducer;