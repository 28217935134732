import ModalComponent from "common/components/Model";
import Grid from "@mui/material/Grid";
import MDBox from "common/components/MDBox";
import Icon from "@mui/material/Icon";
import MDButton from "common/components/MDButton";
import React, {useEffect, useState} from "react";
import './style.css'
import {useDispatch, useSelector} from "react-redux";
import {deleteRegistration, postCertificate} from "services/student/student";
import Swal from "sweetalert2";
import { getCertificateUrl } from "common/utils/CommonFunctions/index";

const Toast = Swal.mixin({
    toast: true,
    position: "top-right",
    showConfirmButton: false,
    zIndex: 10,
    timer: 2000,
    onOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

const GenerateCertificateModel = ({setShowModal, showModal, email,  uid }) => {

    const dispatch = useDispatch()
    const [buttonClick, setButtonClick] = useState(false);
    // const StudentCertificate = useSelector(state => state.getStudentCertificateSlice.certificate);
    const CertificatePost = useSelector(state => state.postCertificateSlice.certificate);
    const [studentCertificate, setStudentCertificate] = useState("");

    const copyLink = () => {
        navigator.clipboard.writeText(studentCertificate);
    };

    useEffect(() => {
        // dispatch(getCertificate(uid));
        setStudentCertificate(getCertificateUrl(uid));
    }, []);

    const handleSend = () => {
        Swal.fire({
            title: "Are you sure?",
            text: "Do you want to send mail?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Send",
        }).then((result) => {
            if (result.isConfirmed) {
                setButtonClick(true);
                dispatch(postCertificate(uid));
            }
        });
    };

useEffect(() => {
    if(buttonClick && !CertificatePost.isLoading) {
        if (CertificatePost.isSuccess){
            Toast.fire({
                icon: "success",
                title: "Send E-Mail Successfully",
              });
            setShowModal(false);
        } else {
            Toast.fire({
                icon: "error",
                title: "Something went Wrong !",
              });
        }
        setButtonClick(false);
    }
}, [CertificatePost]);



    return (
        <ModalComponent setShowModal={setShowModal} showModal={showModal} maxWidth={"md"}>
            <MDBox m={5}>
                <Grid container gap={2}>
                    <Grid item xs={12} md={2} xl={2} className="text-box">
                        Certificate
                    </Grid>
                    <Grid item xs={12} md={9} xl={9} className="link-box">
                        <Grid item xs={9} md={11} xl={11}>{studentCertificate}</Grid>
                        <Grid item xs={2} md={1} xl={1}>
                            <MDButton variant="contained" iconOnly={true} onClick={copyLink}>
                                <Icon fontSize="large">
                                    copy
                                </Icon>
                            </MDButton>
                        </Grid>
                    </Grid>
                    <Grid  item xs={12} md={5} lg={5} xl={5} className="text-box">
                        Send course certificate to the student
                    </Grid>
                    <Grid item xs={12} md={6} lg={6} xl={4} container className="email-box">
                        {email}
                    </Grid>
                    <Grid item xs={12} xl={2}>
                        <MDButton variant="gradient" color="info" onClick={handleSend}
                                  sx={{
                                      width: {
                                          xs: "50%",
                                          sm: "30%",
                                          md: "25%",
                                          lg: "20%",
                                          xl: "90%"
                                      }
                                  }}
                        >
                            Send
                            <Icon fontSize="large" className="icon" sx={{width: "40%"}}>
                                send
                            </Icon>
                        </MDButton>
                    </Grid>
                    <Grid item xs={12}>
                        <img src={studentCertificate} alt="" className="image-field"/>
                    </Grid>
                </Grid>
            </MDBox>
        </ModalComponent>
    );
}
export default GenerateCertificateModel;
