import { createSlice } from "@reduxjs/toolkit";
import { postCourse } from "services/course/course";

const initialState = {
    course : {
        data: [],
        isLoading: false,
        isSuccess: false,
        errorMessage: ''
    }
}
export const saveCourseSlice = createSlice({
    name: 'saveCourse',
    initialState,
    extraReducers: {
        [postCourse.pending]: (state) => {
            state.course.isLoading = true;
        },
        [postCourse.fulfilled]: (state, { payload }) => {
            state.course.isLoading = false;
            state.course.isSuccess = true;
            state.course.data = payload;
        },
        [postCourse.rejected]: (state, { payload }) => {
            state.course.isLoading = false;
            state.course.isSuccess = false;
            state.course.errorMessage = payload
        }
    }
})
export default saveCourseSlice.reducer;