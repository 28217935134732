import {createSlice} from "@reduxjs/toolkit";
import {createReview, getReviewList} from "services/review/review";

const initialState = {
    reviews : {
        data : [],
        isLoading: false,
        isSuccess: false,
        errorMessage: ''
    }
}

export const getReviewListSlice = createSlice({
    name: 'getReview',
    initialState,
    extraReducers:{
        [getReviewList.pending]:(state) =>{
            state.reviews.isLoading = true
            state.reviews.errorMessage = '';
        },
        [getReviewList.fulfilled]: (state, {payload}) =>{
            state.reviews.isSuccess = true;
            state.reviews.isLoading = false;
            state.reviews.data = payload;
        },
        [getReviewList.rejected]: (state, {payload}) =>{
            state.reviews.isSuccess = false;
            state.reviews.isLoading = false;
            state.reviews.errorMessage = payload;
        }
    }
})

export default getReviewListSlice.reducer;