import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  InputAdornment,
  Icon,
  OutlinedInput,
} from "@mui/material";
import React, { useState } from "react";
import "./style.css";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  tableCell: {
    maxWidth: 170,
  },
}));

function TableComponent(props) {
  const [searchQuery, setSearchQuery] = useState("");
  const { data, columns } = props;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };
  const filteredData = data.filter((row) =>
    columns.some((column) => {
      const value = row[column.s_key] ? row[column.s_key] : row[column.field];
      if (value !== null && value !== undefined) {
        return (
          value.toString().toLowerCase().indexOf(searchQuery.toLowerCase()) > -1
        );
      }
      return false;
    })
  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const slicedData = filteredData.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const classes = useStyles();

  return (
    <Grid>
      <OutlinedInput
        className="search-bar"
        placeholder="Search Here"
        value={searchQuery}
        onChange={handleSearch}
        endAdornment={
          <InputAdornment position="end">
            <Icon fontSize="medium">search</Icon>
          </InputAdornment>
        }
      />
      <Table>
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell align="center" key={column.field}>
                {column.headerName}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {slicedData.map((row) => {
            return (
              <TableRow key={row.id} hover onClick={() => {}}>
                {columns.map((column) => (
                  <TableCell
                    key={column.field}
                    style={{
                      paddingRight: 0,
                      position: "relative",
                    }}
                    className={classes.tableCell}
                  >
                    {row[column.field]}
                  </TableCell>
                ))}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>

      {/* Row per page */}
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={filteredData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Grid>
  );
}

export default TableComponent;
