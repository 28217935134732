import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import MDBox from "common/components/MDBox";
import MDButton from "common/components/MDButton";
import Icon from "@mui/material/Icon";
import TableComponent from "common/components/table";
import MobileList from "common/components/mobileList/MobileList";
import AddStudentModel from "component/AddStudentModel";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { deleteStudent, getStudentList } from "services/student/student";
import Swal from "sweetalert2";
import ScrollContainer from "react-indiana-drag-scroll";
import CopyText from "common/components/CopyText/CopyText";

const Toast = Swal.mixin({
  toast: true,
  position: "top-right",
  showConfirmButton: false,
  timer: 2000,
  onOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});

const columns = [
  { field: "id", headerName: "ID", accessor: "id", s_key:'s_id' },
  { field: "name", headerName: "Name", accessor: "name",s_key:'s_name' },
  { field: "nic", headerName: "NIC", accessor: "nic",s_key:'s_nic' },
  { field: "email", headerName: "Email", accessor: "email",s_key:'s_email' },
  { field: "number", headerName: "Number", accessor: "number",s_key:'s_number' },
  { field: "action", headerName: "Action", accessor: "action" },
];

function Student() {
  const navigate = useNavigate();
  const [popUp, setPop] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [copiedValue, setCopiedValue] = useState("");
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  const [deleteLoader, setDeleteLoader] = useState(false);
  const dispatch = useDispatch();
  const student = useSelector((state) => state.studentSlice.student);
  const studentDelete = useSelector(
    (state) => state.deleteStudentSlice.student
  );

  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to delete this Student?",
      icon: "warning",
      showCancelButton: true,
      // confirmButtonColor: '#3085d6',
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        setDeleteLoader(true);
        dispatch(deleteStudent(id));
      }
    });
  };

  useEffect(() => {
    if (deleteLoader) {
      if (studentDelete.isSuccess && !studentDelete.isLoading) {
        dispatch(getStudentList());
        setDeleteLoader(false);
        Toast.fire({
          icon: "success",
          title: "Student delete successfully!",
        });
      } else if (studentDelete.isError) {
        setDeleteLoader(false);
        Toast.fire({
          icon: "error",
          title: "Failed to delete Student",
        });
      }
    }
  }, [studentDelete]);

  useEffect(() => {
    dispatch(getStudentList());
  }, []);

  useEffect(() => {
    if (student.isSuccess) {
      const data = student.data;
      const array = [];
      data.forEach((val) => {
        array.push({
          id: val.id,
          sId: val.custom_id,
          s_name:val.name,
          name: (
            <Grid
              color="blue"
              onClick={() => {
                navigate(`/student-details/${val.id}`);
              }}
              sx={{ cursor: "pointer" }}
            >
              {val.name}
            </Grid>
          ),
          nic: (
            <CopyText
              val={val.nic}
              copiedValue={copiedValue}
              setCopiedValue={setCopiedValue}
              top="0"
            />
          ),
          s_nic:val.nic,
          email: (
            <CopyText
              val={val.email}
              copiedValue={copiedValue}
              setCopiedValue={setCopiedValue}
              top="0"
            />
          ),
          s_email:val.email,
          number: (
            <CopyText
              val={val.contact_no}
              copiedValue={copiedValue}
              setCopiedValue={setCopiedValue}
              top="0"
            />
          ),
          s_number:val.contact_no,
          action: (
            <div className="my-class1">
              <MDButton
                variant="gradient"
                color="error"
                onClick={() => handleDelete(val.id)}
              >
                <Icon fontSize="medium">delete</Icon>
                {windowDimensions.width >= 400 &&
                  windowDimensions.width <= 750 &&
                  "Delete"}
              </MDButton>
            </div>
          ),
        });
      });
      setTableData(array);
    }
  }, [student.data, copiedValue]);

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    {
      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }
  }, [windowDimensions]);

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }

  return (
    <MDBox pt={6} pb={3}>
      <div className="content">
        <Grid container spacing={6} justifyContent="flex-end">
          <Grid item>
            <MDButton
              variant="gradient"
              color="info"
              onClick={() => {
                setPop(true);
              }}
            >
              <Icon fontSize="large" sx={{ marginRight: 1, marginBottom: 0.2 }}>
                control_point
              </Icon>
              Add New Student
            </MDButton>
          </Grid>
        </Grid>
        <Grid pt={6}>
          {windowDimensions.width > 750 ? (
            <ScrollContainer className="scroll-container">
              <TableComponent data={tableData} columns={columns} />
            </ScrollContainer>
          ) : (
            <MobileList
              COLUMNS={columns}
              dataJson={tableData}
              searchKey={["sId", "name"]}
            />
          )}
        </Grid>
      </div>
      {popUp && <AddStudentModel setShowModal={setPop} showModal={popUp} />}
    </MDBox>
  );
}
export default Student;
