import axios from 'axios';
import { getItem } from "common/utils/Storage/Storage";

const token = getItem('login-token')

const instance = axios.create({
    //Dev server
    // baseURL: 'https://test-main-api.acpt.lk/api',

    // Live server
    baseURL: 'https://main-api.acpt.lk/api',
    headers: { Authorization : `Bearer ${token}`},
});

export default instance;
