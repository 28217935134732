import React, { useState } from "react";
import TblCard from "common/components/TblCard";
import { InputAdornment, OutlinedInput } from "@mui/material";
import Icon from "@mui/material/Icon";

const MobileList = ({ COLUMNS, dataJson, searchKey }) => {
    const [searchQuery, setSearchQuery] = useState("");

    const handleSearch = (event) => {
        setSearchQuery(event.target.value);
    };

    const filteredData = dataJson.filter((row) =>
        COLUMNS.some(
            (column) =>
                row[column.field]
                    .toString()
                    .toLowerCase()
                    .indexOf(searchQuery.toLowerCase()) > -1
        )
    );

    return (
        <div>
            <OutlinedInput
                className="search-bar"
                placeholder="Search Here"
                value={searchQuery}
                onChange={handleSearch}
                endAdornment={
                    <InputAdornment position="end">
                        <Icon fontSize="medium">search</Icon>
                    </InputAdornment>
                }
            />
            {filteredData.map((val, index) => {
                return (
                    <div className="my-class" key={index}>
                        <TblCard COLUMNS={COLUMNS} dataJson={val} searchKey={searchKey} />
                    </div>
                );
            })}
        </div>
    );
};

export default MobileList;
