import React, {useEffect, useState} from "react";
import {Card, CardContent, Grid, TextField, Typography} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import {useDispatch, useSelector} from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import Swal from "sweetalert2";
import MDBox from "common/components/MDBox";
import ModalComponent from "common/components/Model";
import {online} from "config/images";
import {postEmail} from "services/payment/paymentReceipt";
import "./style.css";

export default function PaymentReceipt({setShowModal, showModal, value}) {

    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState();

    const sendMail = useSelector((state) => state.postEmailSlice.payment);
    const getPayment = useSelector((state) => state.getAllPaymentSlice.payment);

    const formatPaymentAmount = (amount) => {
        const formattedAmount = parseFloat(amount).toFixed(2);
        return formattedAmount.replace(/\d(?=(\d{3})+\.)/g, "$&,");
    };

    useEffect(()=> {
        if (getPayment.isSuccess && getPayment.data) {
            const regData = getPayment.data
            console.log(regData.registration_data.intake_data.intake);
            setData(regData.registration_data);
        }
    },[getPayment.data])

    useEffect(() => {
        if (isLoading && !sendMail?.isLoading) {
            if (isLoading && sendMail?.isSuccess && !sendMail?.isLoading) {
                Swal.fire({
                    icon: "success",
                    title: "Sent Email Successfully",
                    toast: true,
                    position: "top",
                    showConfirmButton: false,
                    timer: 3000,
                });
                setIsLoading(false);
            } else {
                Swal.fire({
                    icon: "error",
                    title: "Email not sent",
                    toast: true,
                    position: "top",
                    showConfirmButton: false,
                    timer: 3000,
                });
                setIsLoading(false);
            }
        }
    }, [sendMail]);

    const handleSendMail = () => {
        dispatch(postEmail(value?.payId));
        setIsLoading(true);
    };

    return (
        <ModalComponent setShowModal={setShowModal} showModal={showModal} maxWidth={"xl"}>
            <MDBox pb={3} sx={{padding: {xs: 1, md: 2}, paddingTop: {xs: 3}}}>
                <h4> Payment Details </h4>
                <Card className="PaymentReceipt-borderRadius"
                      sx={{width: "100%", minWidth: 200, bgcolor: "#F4F4F4", maxWidth:450}}
                >
                    <CardContent>
                        <MDBox>
                            <Grid container>
                                <Grid item xl={2} lg={2} md={2} sm={2} xs={3} className="PaymentReceipt-image" >
                                    <img src={online} alt={online} />
                                </Grid>
                                <Grid item xl={10} lg={10} md={10} sm={10} xs={9}  >
                                    <Grid container>
                                        <Grid item xl={7} lg={7} md={7} sm={7} xs={12} >
                                            <div className="PaymentReceipt-color">Payment</div>
                                            <div >
                                                <p className="PaymentReceipt-color2" >
                                                    {data?.course_data?.title}
                                                </p>
                                            </div>
                                        </Grid>
                                        <Grid item xl={5} lg={5} md={5} sm={5} xs={12} >
                                            <div className="PaymentReceipt-date">
                                                <span className="PaymentReceipt-color">Date - </span>
                                                {new Date(value.date).toLocaleDateString("en-GB")}
                                            </div>
                                            <div className="PaymentBill-number">
                                                <span className="PaymentReceipt-color">Bill Number - </span>{value.bill_no}
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </MDBox>
                        <MDBox>
                            <Typography sx={{fontSize: 35, color: "#9470FD", fontWeight: 500, mt: 2}}
                                        color="text.secondary"
                                        gutterBottom
                            >
                                Rs. {formatPaymentAmount(value?.payment)}
                            </Typography>
                        </MDBox>
                    </CardContent>
                </Card>
                <br/>
                <Card className="PaymentReceipt-borderRadius"
                      sx={{width: "100%", minWidth: 200, border: "2px solid #C0C0C0", mb: 2,}}
                >
                    <CardContent>
                        <Grid container>
                            <Grid item xl={12} lg={12} md={12} sm={12} xs={12} >
                                <Typography sx={{fontSize: 15, fontWeight: "bold", color: "#000"}}>
                                    Course Details
                                </Typography>
                                <Typography sx={{fontSize: 12, fontWeight: "bold", color: "#000"}}>
                                    {data?.course_data?.title}
                                </Typography>
                                <Typography sx={{fontSize: 12, fontWeight: "bold", color: "#000"}}>
                                    {data?.intake_data?.intake}
                                </Typography>
                            </Grid>
                        </Grid>
                    </CardContent>
                    <hr className="PaymentReceipt-hr"/>

                    <CardContent>
                        <Typography sx={{fontSize: 15, fontWeight: "bold", color: "#000", mb: 1}}>
                            Student Details
                        </Typography>
                        <Typography sx={{fontSize: 12, fontWeight: 500, color: "#000"}}>
                            {data?.student_data?.name}
                        </Typography>
                        <Typography sx={{fontSize: 12, fontWeight: 500, color: "#000"}}>
                            {data?.student_data?.email}
                        </Typography>
                        <Typography sx={{fontSize: 12, fontWeight: 500, color: "#000"}}>
                            {data?.student_data?.nic}
                        </Typography>
                        <Typography sx={{fontSize: 12, fontWeight: 500, color: "#000"}}>
                            {data?.student_data?.contact_no}
                        </Typography>
                    </CardContent>
                </Card>
                <Grid container spacing={2}>
                    <Grid item xs={8} lg={8}>
                        <TextField
                            sx={{width: "100%"}}
                            disabled
                            id="outlined-disabled"
                            value={data?.student_data?.email}
                        />
                    </Grid>
                    <Grid item xs={4} lg={4}>
                        <Button
                            sx={{width: "100%"}}
                            className="PaymentReceipt-button"
                            variant="contained"
                            disabled={isLoading}
                            onClick={handleSendMail}
                            startIcon={isLoading && <CircularProgress sx={{color: '#efefef'}} size={20} />}
                            endIcon={<SendIcon/>}
                        >
                            Send
                        </Button>
                    </Grid>
                </Grid>
            </MDBox>
        </ModalComponent>
    );
}
