import { createSlice } from "@reduxjs/toolkit";
import { getBLogList } from "services/blog/blog";

const initialState = {
    blog : {
        data: [],
        isLoading: false,
        isSuccess: false,
        errorMessage: ''
    }
}

export const blogSlice = createSlice({
    name: 'blog',
    initialState,
    extraReducers: {
        [getBLogList.pending]: (state) => {
            state.blog.isLoading = true;
        },
        [getBLogList.fulfilled]: (state, { payload }) => {
            state.blog.isLoading = false;
            state.blog.isSuccess = true;
            state.blog.data = payload;
        },
        [getBLogList.rejected]: (state, { payload }) => {
            state.blog.isLoading = false;
            state.blog.isSuccess = false;
            state.user.errorMessage = payload
        }
    }
})

export default blogSlice.reducer;