import { createAsyncThunk } from "@reduxjs/toolkit";
import instance from "../AxiosOrders";

export const getAllPayment = createAsyncThunk('payment/getPayment', async (id, { rejectWithValue }) => {
    try {
        const { data } = await instance.get(`/payment/all/${id}`);
        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});




export const addPayPayment = createAsyncThunk(
    "payment/addPayment",
    async (page, { rejectWithValue }) => {
      try {
        const { data } = await instance.post("/payment",page, {
          headers: { "Content-type": "application/json" },
        });
        return data;
      } catch (error) {
        return rejectWithValue(error.message);
      }
    }
  );
  