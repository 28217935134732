import { createSlice } from "@reduxjs/toolkit";
import { getEventList } from "services/event/event";

const initialState = {
    event : {
        data: [],
        isLoading: false,
        isSuccess: false,
        errorMessage: ''
    }
}

export const eventSlice = createSlice({
    name: 'event',
    initialState,
    extraReducers: {
        [getEventList.pending]: (state) => {
            state.event.isLoading = true;
        },
        [getEventList.fulfilled]: (state, { payload }) => {
            state.event.isLoading = false;
            state.event.isSuccess = true;
            state.event.data = payload;
        },
        [getEventList.rejected]: (state, { payload }) => {
            state.event.isLoading = false;
            state.event.isSuccess = false;
            state.user.errorMessage = payload
        }
    }
})
export default eventSlice.reducer;