import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Icon from "@mui/material/Icon";
import './style.css'
import { defaultLoadMini } from 'config/images';

const ImageUploader = ({ imageUrl, onImageSelect,width,height, postImage  }) => {
    const [image, setImage] = useState(imageUrl);
    const [isHovering, setIsHovering] = useState(false);

    const handleImageSelect = (event) => {
        if (event.target.files && event.target.files[0]) {
            postImage(event.target.files[0])
            let reader = new FileReader();
            reader.onload = (e) => {
                let base64Img = e.target.result;
                setImage(base64Img);
                onImageSelect(base64Img);
            };
            reader.readAsDataURL(event.target.files[0]);
        }
    };

    return (
        <div>
            <input
                className="input-image"
                required
                type="file"
                accept="image/*"
                id="image-input"
                onChange={handleImageSelect}
            />
            {image ? (
                <img
                    className="image-box"
                    src={image}
                    alt="selected image"
                    onClick={() => document.getElementById('image-input').click()}
                    onMouseEnter={() => setIsHovering(true)}
                    onMouseLeave={() => setIsHovering(false)}
                    onError={(e) => {
    e.target.src = defaultLoadMini; 
  }}
                />
            ) : (
                <div
                    className="upload-box"
                    style={{ width: width,height:height }}
                    onClick={() => document.getElementById('image-input').click()}
                >
                    <div >
                        <Icon fontSize="large">add</Icon>
                    </div>
                    <div>Upload</div>
                </div>
            )}
        </div>
    );
};

ImageUploader.propTypes = {
    imageUrl: PropTypes.string,
    onImageSelect: PropTypes.func.isRequired,
};

export default ImageUploader;
