import { createSlice } from "@reduxjs/toolkit";
import {deleteEvent} from "services/event/event";

const initialState = {
    event : {
        data: [],
        isLoading: false,
        isSuccess: false,
        errorMessage: ''
    }
}

export const deleteEventSlice = createSlice({
    name: 'eventDelete',
    initialState,
    extraReducers: {
        [deleteEvent.pending]: (state) => {
            state.event.isLoading = true;
        },
        [deleteEvent.fulfilled]: (state, { payload }) => {
            state.event.isLoading = false;
            state.event.isSuccess = true;
            state.event.data = payload;
        },
        [deleteEvent.rejected]: (state, { payload }) => {
            state.event.isLoading = false;
            state.event.isSuccess = false;
            state.event.errorMessage = payload
        }
    }
})
export default deleteEventSlice.reducer;