import {createAsyncThunk} from "@reduxjs/toolkit";
import instance from "../AxiosOrders";

export const createReview = createAsyncThunk('review/createReview',async (page,{rejectedWithValue}) => {
    try {
        const { data } = await instance.post(`/review`,page,{
            headers:{ "Content-type":"multipart/form-date"}
        });
        return data;
    }catch (error){
        return rejectedWithValue(error.message)
    }
});

export const updateReviewData = createAsyncThunk('review/createReview',async ({page,id},{rejectedWithValue}) => {
    try {
        const { data } = await instance.post(`/review/${id}`,page,{
            headers:{ "Content-type":"multipart/form-date"}
        });
        return data;
    }catch (error){
        return rejectedWithValue(error.message)
    }
});

export const getReviewList = createAsyncThunk('review/get',async (page,{rejectedWithValue}) => {
    try {
        const { data } = await instance.get(`/review`);
        return data;
    }catch (error){
        return rejectedWithValue(error.message)
    }
});

export const deleteReview = createAsyncThunk('review/deleteReview',async (id,{rejectedWithValue}) => {
    try {
        const { data } = await instance.delete(`/review/${id}`);
        return data;
    }catch (error){
        return rejectedWithValue(error.message)
    }
});