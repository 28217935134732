import React, { useEffect, useState } from "react";
import ModalComponent from "common/components/Model";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import MDBox from "common/components/MDBox";
import CommonSelect from "common/components/CustomSelect";
import MDButton from "common/components/MDButton";
import ImageUploader from "common/components/ImageUploader";
import Switch from "@mui/material/Switch";
import { getEventList, postEvent, updateEvent } from "services/event/event";
import { useDispatch, useSelector } from "react-redux";
import {getImageUrl, onImageEdit} from "common/utils/CommonFunctions";
import Swal from "sweetalert2";
import "./style.css";

const Toast = Swal.mixin({
  toast: true,
  position: "top",
  showConfirmButton: false,
  timer: 2000,
  onOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});

const AddEventModel = ({ setShowModal, showModal, updateData, assignData }) => {
  const [updateLoader, setUpdateLoader] = useState(false);
  const [saveLoader, setSaveLoader] = useState(false);
  const [imageUrl, setImageUrl] = useState(
    updateData
      ? getImageUrl(assignData.image)
      : ""
  );
  const [postImage, setPostImage] = useState();
  const [title, setTitle] = useState(updateData ? assignData.title : "");
  const [location, setLocation] = useState(
    updateData ? assignData.location : ""
  );
  const [event_content, setEvent_content] = useState(
    updateData ? assignData.event_content : ""
  );
  const [status, setStatus] = useState(updateData ? assignData.status : "");
  const [date, setDate] = useState(updateData ? assignData.date : "");
  const [tag, setTag] = useState(updateData ? assignData.tag : "");
  const dispatch = useDispatch();
  const event = useSelector((state) => state.SaveEventSlice.event);
  const updateEventSlice = useSelector((state) => state.UpdateEventSlice.event);
  const [isValidImage, setIsValidImage] = useState(true);


  const handlePostImage = (val) => {
    const img = new Image();
    img.onload = function () {
      if (img.width === 3000 && img.height === 3000) {
        setIsValidImage(true);
        setPostImage(val);
      } else {
        setIsValidImage(false);
      }
    };
    img.src = URL.createObjectURL(val);
  };

  const handleChange = (event) => {
    setTag(event.target.value);
  };

  const handleStatusChange = (e) => {
    const newStatus = e.target.checked ? "active" : "inactive";
    setStatus(newStatus);
  };

  const options = [
    { label: "Online", value: "Online" },
    { label: "Physical", value: "Physical" },
    { label: "Online / Physical", value: "Online / Physical" },
  ];

  const saveEvent = () => {
    const form = new FormData();
    form.append("title", title);
    form.append("tag", tag);
    form.append("event_content", event_content);
    form.append("location", location);
    form.append("date", date);
    form.append("image", postImage);
    dispatch(postEvent(form));
    setSaveLoader(true);
  };

  const eventUpdate = () => {
    const form = new FormData();
    form.append("image", postImage);
    form.append("title", title);
    form.append("tag", tag);
    form.append("event_content", event_content);
    form.append("location", location);
    form.append("date", date);
    form.append("status", status);
    setUpdateLoader(true);
    dispatch(updateEvent({ id: assignData.id, updateEvents: form }));
  };

  useEffect(() => {
    if (updateData) {
      onImageEdit(
          getImageUrl(assignData.image)
      ).then((file) => setPostImage(file));
    }
  }, []);

  useEffect(() => {
    if (saveLoader) {
      setSaveLoader(false);
      if (event.isSuccess && !event.isLoading) {
        clearText();
        setShowModal(false);
        dispatch(getEventList());
        Toast.fire({
          icon: "success",
          title: "Event Posted Successfully",
        });
      } else if (event.isError) {
        Toast.fire({
          icon: "error",
          title: "Invalid input",
        });
      }
    }
  }, [event.data]);

  useEffect(() => {
    if (updateLoader) {
      setUpdateLoader(false);
      if (updateEventSlice.isSuccess && !updateEventSlice.isLoading) {
        setShowModal(false);
        dispatch(getEventList());
        Toast.fire({
          icon: "success",
          title: "Event Updated Successfully",
        });
      } else if (updateEventSlice.isError) {
        setUpdateLoader(false);
        Toast.fire({
          icon: "error",
          title: "Invalid input",
        });
      }
    }
  }, [updateEventSlice.data]);

  const clearText = () => {
    setTitle("");
    setEvent_content("");
    setTag("");
    setLocation("");
    setPostImage("");
    setDate("");
    setStatus("");
  };

  return (
    <ModalComponent
      setShowModal={setShowModal}
      showModal={showModal}
      maxWidth={"md"}
    >
      <MDBox
        pb={3}
        sx={{
          padding: {
            xs: 2,
            md: 4,
          },
          paddingTop: {
            xs: 6,
          },
          mt: 2,
        }}
      >
        <Grid container spacing={2}>
          {/* First row */}
          <Grid item xs={12} sm={4} md={4} lg={5}>
            <TextField
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              id="outlined-helperText"
              label="Title"
              sx={{
                width: {
                  xs: "100%",
                  sm: "100%",
                  md: "100%",
                },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={4} lg={3}>
            <CommonSelect
              id="outlined-helperText"
              options={options}
              value={tag}
              onChange={handleChange}
              label="Tag"
            />
          </Grid>
          <Grid item xs={12} sm={4} md={4} lg={4}>
            <TextField
              sx={{
                width: {
                  xs: "100%",
                  md: "100%",
                },
              }}
              id="date"
              label="Date"
              type="date"
              value={date}
              onChange={(e) => setDate(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          {/* Second row*/}
          <Grid item xs={12} sm={6} md={5} lg={5} gap={2}>
            <Grid container gap={2}>
              <Grid item xs={12} sm={12} md={12} lg={12} >
                <TextField
                    value={location}
                    onChange={(e) => setLocation(e.target.value)}
                    id="outlined-helperText"
                    label="Location"
                    sx={{
                      width: {
                        xs: "100%",
                        sm: "100%",
                        md: "100%",
                      },
                    }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} >
                <TextField
                    value={event_content}
                    onChange={(e) => setEvent_content(e.target.value)}
                    id="outlined-multiline-static"
                    label="Content"
                    multiline
                    rows={6}
                    sx={{
                      width: {
                        xs: "100%",
                        md: "100%",
                      },
                    }}
                />
              </Grid>
            </Grid>


          </Grid>
          <Grid item xs={12} sm={6} md={5} lg={5} display={"flex"} flexDirection={"column"} justifyContent={"left"}>
            <ImageUploader
              postImage={handlePostImage}
              imageUrl={imageUrl}
              onImageSelect={setImageUrl}
              width="150px"
              height="150px"
            />{" "}
            {!isValidImage && (
                <div className="image-upload invalid-image">You are added invalid image</div>
            )}
            {updateData && (
              <Grid>
                Status
                <Switch
                  checked={status === "active"}
                  onChange={handleStatusChange}
                  color="secondary"
                />
              </Grid>
            )}
          </Grid>

          {/* Third row */}
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6} xl={6}>
                <MDButton
                  variant="outlined"
                  color="secondary"
                  disabled={updateData}
                  sx={{
                    width: {
                      xs: "100%",
                      md: "100%",
                    },
                  }}
                  onClick={() => {
                    clearText();
                  }}
                >
                  Clear
                </MDButton>
              </Grid>
              <Grid item xs={12} sm={6} md={6} xl={6}>
                <MDButton
                  variant="gradient"
                  disabled={
                    title === "" ||
                    tag === "" ||
                    event_content === "" ||
                    location === "" ||
                    date === "" ||
                    !imageUrl ||
                    !isValidImage||event.isLoading||updateEventSlice.isLoading
                  }
                  color={updateData ? "success" : "info"}
                  sx={{
                    width: {
                      xs: "100%",
                      md: "100%",
                    },
                  }}
                  onClick={
                    updateData
                      ? () => {
                          eventUpdate();
                        }
                      : () => {
                          saveEvent();
                        }
                  }
                >
                  {updateData ? "Update Event" : "Add Event"}
                </MDButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </MDBox>
    </ModalComponent>
  );
};
export default AddEventModel;
