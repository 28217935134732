import { createSlice } from "@reduxjs/toolkit";
import { getStudentList } from "services/student/student";

const initialState = {
    student : {
        data: [],
        isLoading: false,
        isSuccess: false,
        errorMessage: ''
    }
}
export const studentSlice = createSlice({
    name: 'student',
    initialState,
    extraReducers: {
        [getStudentList.pending]: (state) => {
            state.student.isLoading = true;
        },
        [getStudentList.fulfilled]: (state, { payload }) => {
            state.student.isLoading = false;
            state.student.isSuccess = true;
            state.student.data = payload;
        },
        [getStudentList.rejected]: (state, { payload }) => {
            state.student.isLoading = false;
            state.student.isSuccess = false;
            state.student.errorMessage = payload
        }
    }
})
export default studentSlice.reducer;