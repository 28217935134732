import { createSlice } from "@reduxjs/toolkit";
import { updateEvent } from "services/event/event";

const initialState = {
    event : {
        data: [],
        isLoading: false,
        isSuccess: false,
        errorMessage: ''
    }
}
export const updateEventSlice = createSlice({
    name: 'eventUpdate',
    initialState,
    extraReducers: {
        [updateEvent.pending]: (state) => {
            state.event.isLoading = true;
        },
        [updateEvent.fulfilled]: (state, { payload }) => {
            state.event.isLoading = false;
            state.event.isSuccess = true;
            state.event.data = payload;
        },
        [updateEvent.rejected]: (state, { payload }) => {
            state.event.isLoading = false;
            state.event.isSuccess = false;
            state.event.errorMessage = payload
        }
    }
})
export default updateEventSlice.reducer;
