import { createAsyncThunk } from "@reduxjs/toolkit";
import instance from "../AxiosOrders";

export const getCourseList = createAsyncThunk('course/getCourseList', async (page, { rejectWithValue }) => {
    try {
        const { data } = await instance.get(`/course`);
        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

export const postCourse = createAsyncThunk('course/postCourse', async (page, { rejectWithValue }) => {
    try {
        const { data } = await instance.post(`/course`, page, {
            headers: {"Content-type": "multipart/form-date"},
        });
        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
})

export const updateCourse = createAsyncThunk('course/updateCourse', async ({ id, updatedCourse }, { rejectWithValue }) => {
    try {
        const { data } = await instance.post(`/course/${id}`, updatedCourse,{
            headers: {"Content-type": "multipart/form-date"},
        });

        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});
export const deleteCourse = createAsyncThunk('course/deleteCourse', async (id, { rejectWithValue }) => {
        try {
            const data = await instance.delete(`/course/${id}`);
            return data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);
