import { createSlice } from "@reduxjs/toolkit";
import { deleteRegistration } from "services/student/student";

const initialState = {
    registration : {
        data: [],
        isLoading: false,
        isSuccess: false,
        errorMessage: ''
    }
}
export const deleteRegistrationSlice = createSlice({
    name: 'deleteRegistration',
    initialState,
    extraReducers: {
        [deleteRegistration.pending]: (state) => {
            state.registration.isLoading = true;
        },
        [deleteRegistration.fulfilled]: (state, { payload }) => {
            state.registration.isLoading = false;
            state.registration.isSuccess = true;
            state.registration.data = payload;
        },
        [deleteRegistration.rejected]: (state, { payload }) => {
            state.registration.isLoading = false;
            state.registration.isSuccess = false;
            state.user.errorMessage = payload
        }
    }
})

export default deleteRegistrationSlice.reducer;