//Dev server
// const baseUrl = "https://test-api.acpt.lk";

// Live server
const baseUrl = "https://main-api.acpt.lk";

export const onImageEdit = async (imgUrl) => {
    const response = await fetch(imgUrl);
    const blob = await response.blob();
    const file = new File([blob], "profileImage.jpg", {
        type: blob.type,
    });
    return file;
}
export const getImageUrl = (imageName) => {
    const Url = baseUrl+"/storage/images/";
    return Url + imageName;
};

export const getCertificateUrl = (id) => {
    const Url = baseUrl+"/api/student/";
    return Url + id;
};

export const getAdminPanelUrl = () => {
    return "https://admin.acpt.lk"
    // return "https://test-admin.acpt.lk"

}
