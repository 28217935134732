import { createAsyncThunk } from "@reduxjs/toolkit";
import instance from "../AxiosOrders";

export const getBLogList = createAsyncThunk('blog/getBLogList', async (page, { rejectWithValue }) => {
    try {
        const { data } = await instance.get(`/blog`);
        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

export const postBlog = createAsyncThunk('blog/postBLog', async (page, { rejectWithValue }) => {
    try {
        const { data } = await instance.post(`/blog`, page, {
            headers: {"Content-type": "multipart/form-date"},
        });
        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
})

export const deleteBlog = createAsyncThunk('blog/deleteBlog', async (id, { rejectWithValue }) => {
        try {
            const data = await instance.delete(`/blog/${id}`);
            return data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const updateBlogs = createAsyncThunk('intake/updateIntake', async ({ id, updatedBlog }, { rejectWithValue }) => {
    try {
        const { data } = await instance.post(`/blog/${id}`, updatedBlog,{
            headers: {"Content-type": "multipart/form-date"},
        });

        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});