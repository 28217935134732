import {createSlice} from "@reduxjs/toolkit";
import {getStudentCount} from "services/student/student";

const initialState = {
    count : {
        data:0,
        isLoading: false,
        isSuccess: false,
        errorMessage: ''
    }
}

export const studentCountSlice = createSlice({
    name: 'count',
    initialState,
    extraReducers: {
        [getStudentCount.pending]: (state) => {
            state.count.isLoading = true;
        },
        [getStudentCount.fulfilled]: (state, { payload }) => {
            state.count.isLoading = false;
            state.count.isSuccess = true;
            state.count.data = payload;
        },
        [getStudentCount.rejected]: (state, { payload }) => {
            state.count.isLoading = false;
            state.count.isSuccess = false;
            state.count.errorMessage = payload
        }
    }
})
export default studentCountSlice.reducer;