import { createSlice } from "@reduxjs/toolkit";
import { getPaymentReceipt } from "services/payment/paymentReceipt";

const initialState = {
    payment : {
        data: [],
        isLoading: false,
        isSuccess: false,
        errorMessage: ''
    }
}
export const getPaymentReceiptSlice = createSlice({
    name: 'payment',
    initialState,
    extraReducers: {
        [getPaymentReceipt.pending]: (state) => {
            state.payment.isLoading = true;
        },
        [getPaymentReceipt.fulfilled]: (state, { payload }) => {
            state.payment.isLoading = false;
            state.payment.isSuccess = true;
            state.payment.data = payload;
        },
        [getPaymentReceipt.rejected]: (state, { payload }) => {
            state.payment.isLoading = false;
            state.payment.isSuccess = false;
            state.payment.errorMessage = payload
        }
    }
})

export default getPaymentReceiptSlice.reducer;