import { createSlice } from "@reduxjs/toolkit";
import { addPayPayment } from "services/payment/payment";

const initialState = {
    payment : {
        data: [],
        isLoading: false,
        isSuccess: false,
        errorMessage: ''
    }
}
export const addPayPaymentSlice = createSlice({
    name: 'payment',
    initialState,
    extraReducers: {
        [addPayPayment.pending]: (state) => {
            state.payment.isLoading = true;
        },
        [addPayPayment.fulfilled]: (state, { payload }) => {
            state.payment.isLoading = false;
            state.payment.isSuccess = true;
            state.payment.data = payload;
        },
        [addPayPayment.rejected]: (state, { payload }) => {
            state.payment.isLoading = false;
            state.payment.isSuccess = false;
            state.payment.errorMessage = payload
        }
    }
})
export default addPayPaymentSlice.reducer;