import { createAsyncThunk } from "@reduxjs/toolkit";
import instance from "../AxiosOrders";

export const getEventList = createAsyncThunk('event/getEventList',async (page, { rejectWithValue }) => {
    try {
        const {data} = await instance.get('/event');
        return data;
    }catch (error){
        return rejectWithValue(error.message);
    }
});

export const postEvent = createAsyncThunk('event/postEvent',async (page, { rejectWithValue }) => {
   try {
       const {data} = await instance.post('/event',page,{
           headers: {"Content-type": "multipart/form-date"},
       });
       return data;
   }catch (error){
       return rejectWithValue(error.message);
   }
});

export const deleteEvent = createAsyncThunk('event/${id}',async (id, { rejectWithValue }) => {
    try {
        const {data} = await instance.delete(`/event/${id}`)
        return data;
    }catch (error){
        return rejectWithValue(error.message);
    }
});

export const updateEvent = createAsyncThunk('event/updateEvent',async ({id, updateEvents}, { rejectWithValue }) => {
    try {
        const {data} = await instance.post(`/event/${id}`,updateEvents,{
            headers: {"Content-type": "multipart/form-date"},
        });
        return data;
    }catch (error){
        return rejectWithValue(error.message);
    }
});