import { createSlice } from "@reduxjs/toolkit";
import { deleteBlog } from "services/blog/blog";

const initialState = {
    blog : {
        data: [],
        isLoading: false,
        isSuccess: false,
        errorMessage: ''
    }
}
export const deleteBlogSlice = createSlice({
    name: 'deleteBlog',
    initialState,
    extraReducers: {
        [deleteBlog.pending]: (state) => {
            state.blog.isLoading = true;
        },
        [deleteBlog.fulfilled]: (state, { payload }) => {
            state.blog.isLoading = false;
            state.blog.isSuccess = true;
            state.blog.data = payload;
        },
        [deleteBlog.rejected]: (state, { payload }) => {
            state.blog.isLoading = false;
            state.blog.isSuccess = false;
            state.user.errorMessage = payload
        }
    }
})

export default deleteBlogSlice.reducer;
