import { createSlice } from "@reduxjs/toolkit";
import { getCourseDetails } from "services/student/student";

const initialState = {
    student : {
        data: [],
        isLoading: false,
        isSuccess: false,
        errorMessage: ''
    }
}
export const getCourseDetailsSlice = createSlice({
    name: 'student',
    initialState,
    extraReducers: {
        [getCourseDetails.pending]: (state) => {
            state.student.isLoading = true;
        },
        [getCourseDetails.fulfilled]: (state, { payload }) => {
            state.student.isLoading = false;
            state.student.isSuccess = true;
            state.student.data = payload;
        },
        [getCourseDetails.rejected]: (state, { payload }) => {
            state.student.isLoading = false;
            state.student.isSuccess = false;
            state.user.errorMessage = payload
        }
    }
})

export default getCourseDetailsSlice.reducer;