import React, {useState, useEffect} from "react";
import {useSelector} from "react-redux";
import Button from "@mui/material/Button";
import MDBox from "common/components/MDBox";
import "./style.css";

const HeaderNavigator = ({activeTab, setActiveTab}) => {

    const [register, setRegister] = useState(false);
    const [fullPay, setFullPay] = useState(false);
    const [installment, setInstallment] = useState(false);

    const getPayment = useSelector((state) => state.getAllPaymentSlice.payment);

    const setStatus = (reg, full, ins, active) => {
        setRegister(reg);
        setFullPay(full);
        setInstallment(ins);
        setActiveTab(active);
    }

    useEffect(()=> {
        if (getPayment.isSuccess && getPayment.data) {
            const regData = getPayment.data
            const reg = regData.registration_data.payment_data.filter((val)=> val.status === 'registration')
            const ins = regData.registration_data.payment_data.filter((val)=> val.status === 'installment')
            const ful = regData.registration_data.payment_data.filter((val)=> val.status === 'full-payment')

            if(regData.amount_due === 0 || ful.length > 0){
                setStatus(true, true, true, 'installment')
                return
            }
            if(regData.registration_data.payment_data.length === 0 && regData.registration_data.intake_data.reg_fee === 1){
                setStatus(false, false, true, 'registration')
            }
            if(reg.length > 0 || regData.registration_data.intake_data.reg_fee !== 1){
                setStatus(true, false, false, 'full-payment')
            }
            if(reg.length > 0 || ins.length > 0){
                setStatus(true, true, false, 'installment')
            }
        }
    },[getPayment.data]);

    return (
        <div className="headerNavbar-button" style={{overflow: "auto", maxHeight: "100vh"}}>
            <MDBox
                sx={{
                    borderRadius: 4,
                    display: "flex",
                    overflow: "auto",
                    bgcolor: "#F4F4F4",
                    minWidth: {xl: "100%", lg: "100%", md: "350px", xs: "100%"},
                    margin: "0 auto",
                    p: 1,
                }}
            >
                <Button
                    sx={{pr: 3, pl: 3}}
                    disabled={register}
                    color="inherit"
                    size="small"
                    variant={activeTab === "registration" ? "contained" : ""}
                    onClick={() => setActiveTab("registration")}
                >
                    Registration Fee
                </Button>{" "}
                &nbsp;
                <Button
                    sx={{pr: 3, pl: 3}}
                    disabled={fullPay}
                    color="inherit"
                    size="small"
                    variant={activeTab === "full-payment" ? "contained" : ""}
                    onClick={() => setActiveTab("full-payment")}
                >
                    Full Payment
                </Button>{" "}
                &nbsp;
                <Button
                    sx={{pr: 3, pl: 3}}
                    disabled={installment}
                    color="inherit"
                    size="small"
                    variant={activeTab === "installment" ? "contained" : ""}
                    onClick={() =>
                        setActiveTab("installment")
                    }
                >
                    Instalment payment
                </Button>
            </MDBox>
        </div>
    );
};

export default HeaderNavigator;
