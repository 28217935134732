import { createAsyncThunk } from "@reduxjs/toolkit";
import instance from "../AxiosOrders";

export const getIntakeList = createAsyncThunk('intake/getIntakeList', async (page, { rejectWithValue }) => {
    try {
        const { data } = await instance.get(`/intake`);
        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

export const getAllIntakeList = createAsyncThunk('intake/getAllIntakeList', async (page, { rejectWithValue }) => {
    try {
        const { data } = await instance.get(`/intake/all`);
        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

export const postIntake = createAsyncThunk('intake/postIntake', async (page, { rejectWithValue }) => {
    try {
        const { data } = await instance.post(`/intake`, page, {
            headers: {"Content-type": "multipart/form-date"},
        });
        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
})

export const updateIntake = createAsyncThunk('intake/updateIntake', async ({ id, intakeUpdate }, { rejectWithValue }) => {
    try {
        const { data } = await instance.post(`/intake/${id}`, intakeUpdate,{
            headers: {"Content-type": "multipart/form-date"},
        });

        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

export const deleteIntake = createAsyncThunk('intake/deleteIntake', async (id, { rejectWithValue }) => {
        try {
            const data = await instance.delete(`/intake/${id}`);
            return data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);