import { createSlice } from "@reduxjs/toolkit";
import { postCertificate } from "services/student/student";

const initialState = {
    certificate : {
        data: [],
        isLoading: false,
        isSuccess: false,
        errorMessage: ''
    }
}

export const postCertificateSlice = createSlice({
    name: 'certificate',
    initialState,
    extraReducers: {
        [postCertificate.pending]: (state) => {
            state.certificate.isLoading = true;
        },
        [postCertificate.fulfilled]: (state, { payload }) => {
            state.certificate.isLoading = false;
            state.certificate.isSuccess = true;
            state.certificate.data = payload;
        },
        [postCertificate.rejected]: (state, { payload }) => {
            state.certificate.isLoading = false;
            state.certificate.isSuccess = false;
            state.certificate.errorMessage = payload
        }
    }
})
export default postCertificateSlice.reducer;