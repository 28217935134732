import { createSlice } from "@reduxjs/toolkit";
import { postStudent } from "services/student/student";

const initialState = {
    student : {
        data: [],
        isLoading: false,
        isSuccess: false,
        errorMessage: ''
    }
}
export const saveStudentSlice = createSlice({
    name: 'saveStudent',
    initialState,
    extraReducers: {
        [postStudent.pending]: (state) => {
            state.student.isLoading = true;
        },
        [postStudent.fulfilled]: (state, { payload }) => {
            state.student.isLoading = false;
            state.student.isSuccess = true;
            state.student.data = payload;
        },
        [postStudent.rejected]: (state, { payload }) => {
            state.student.isLoading = false;
            state.student.isSuccess = false;
            state.student.errorMessage = payload
        }
    }
})
export default saveStudentSlice.reducer;