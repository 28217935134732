import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {Card, Grid} from "@mui/material";
import {eye, correct} from "config/images";
import MDButton from "../MDButton";
import PaymentReceipt from "component/PaymentReceipt";
import "./style.css";

export default function PaymentTableCard() {

    const [popUp, setPopup] = useState(false);
    const [array, setArray] = useState([]);
    const [valuePopup, setValuepopup] = useState({});

    const getPayment = useSelector((state) => state.getAllPaymentSlice.payment);

    useEffect(()=> {
        if (getPayment.isSuccess && getPayment.data) {
            const regData = getPayment.data;
            const newArray = [];
            regData.registration_data.payment_data.forEach((val)=> {
                newArray.push({
                    payId: val?.id,
                    status: val?.status,
                    payment: val?.payment,
                    date: val?.created_at,
                    bill_no: val?.bill_no,
                })
            });
            setArray(newArray)
        }
    },[getPayment.data])

    const handleEyeClick = (amount) => {
        setPopup(true);
        setValuepopup(amount);
    };
    const capitalizeEachWord = (str) => {
        const s = str.replace(/-/g, ' ');
        return s.replace(/\b\w/g, (match) => match.toUpperCase());
    }

    return (
        <div>
            {array.map((item, index) => (
                <Card id={index} sx={{flexGrow: 1, bgcolor: "#FFF", mt: 1}} variant="outlined">
                    <Grid container className="PaymentTableCard-container">
                        <Grid Item xs={12} sm={1} md={1} xl={1} lg={1}>
                            <img src={correct} alt={correct} className="PaymentTableCard-column2 margin-on-md"/>
                        </Grid>

                        <Grid Item xs={12} sm={1} md={1} xl={1} lg={1}>
                            <div className="PaymentTableCard-column1 hide-on-md">
                                <div>&nbsp;</div>
                            </div>
                        </Grid>
                        <Grid Item xs={12} sm={10} md={6} xl={3} lg={3}>
                            <div
                                className="PaymentTableCard-column paymentTable-grey margin-on-md"
                                id="PaymentTableCard-title3"
                            >
                                {capitalizeEachWord(item?.status)}
                            </div>
                        </Grid>
                        <Grid Item xs={12} sm={4} md={4} xl={3} lg={3}>
                            <div
                                className="PaymentTableCard-column paymentTable-lGrey margin-on-md"
                                id="PaymentTableCard-title3"
                            >
                                {new Date(item.date).toLocaleDateString("en-GB")}
                            </div>
                        </Grid>
                        <Grid Item xs={12} sm={4} md={4} xl={3} lg={3}>
                            <div
                                className="PaymentTableCard-column paymentTable-green margin-on-md"
                                id="PaymentTableCard-title3"
                            >
                                {item?.payment}
                            </div>
                        </Grid>
                        <Grid Item xs={12} sm={4} md={4} xl={1} lg={1}>
                            <MDButton>
                                <img
                                    src={eye}
                                    alt={eye}
                                    className="PaymentTableCard-column2 margin-on-md"
                                    onClick={() => {
                                        handleEyeClick(item);
                                    }}
                                />
                            </MDButton>
                        </Grid>
                    </Grid>
                </Card>
            ))}
            {popUp && (
                <PaymentReceipt
                    setShowModal={setPopup}
                    showModal={popUp}
                    value={valuePopup}
                />
            )}
        </div>
    );
}
