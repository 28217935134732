import {Card, CardActionArea} from "@mui/material";
import React from "react";
import "./style.css";

const RightNavbarCard = ({item}) => {

    const handleCardClick = (item) => {
        const cardContent = `${item.name}`;
        navigator.clipboard.writeText(cardContent);
    };

    return (
        <Card
            sx={{
                height: "100%",
                width: "100%",
                mb: "5%",
                border: "1px solid #5EB562 ",
            }}
        >
            <CardActionArea onClick={() => handleCardClick(item)}>
                <div className="rightNavFlex-container">
                    <img
                        src={item.icon}
                        alt="icon-image"
                        className="rightNavSmall-column2"
                    />
                    <div className="rightNavSmall-column1">
                        <div className="vertical-line">&nbsp;</div>
                    </div>

                    <div className="rightNavBig-column" id="title2">
                        {item.name}
                    </div>
                </div>
            </CardActionArea>
        </Card>
    );
};

export default RightNavbarCard;
