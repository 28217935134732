import { createAsyncThunk } from "@reduxjs/toolkit";
import instance from "../AxiosOrders";

export const getPaymentReceipt = createAsyncThunk('payment/PaymentReceipt', async (id, { rejectWithValue }) => {
    try {
        const {data} = await instance.get(`/payment/receipt/${id}`, {
            headers: {
                'Accept':"*/*",
                'Accept-Encoding':"gzip,deflate,br"
        
        },
        responseType: "blob",
        });
        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});


export const postEmail = createAsyncThunk( "payment/postEmail", async (id, { rejectWithValue }) => {
      try {
        const { data } = await instance.post(`/receipt/mail/${id}`, {
          headers: { "Content-type": "application/json" },
        });
        return data;
      } catch (error) {
        return rejectWithValue(error.message);
      }
    }
  );
  


