import {createSlice} from "@reduxjs/toolkit";
import {getCertificate} from "services/student/student";

const initialState = {
    certificate : {
        data :'',
        isLoading: false,
        isSuccess: false,
        errorMessage: ''
    }
}

export const getStudentCertificateSlice = createSlice({
    name: 'certificate',
    initialState,
    extraReducers:{
        [getCertificate.pending]: (state)=>{
            state.certificate.isLoading = true;
        },
        [getCertificate.fulfilled]: (state, { payload }) => {
            state.certificate.isLoading = false;
            state.certificate.isSuccess = true;
            state.certificate.data = payload;
        },
        [getCertificate.rejected]: (state, { payload }) => {
            state.certificate.isLoading = false;
            state.certificate.isSuccess = false;
            state.certificate.errorMessage = payload
        }
    }
})
export default getStudentCertificateSlice.reducer;