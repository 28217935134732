import { createSlice } from "@reduxjs/toolkit";
import { completeStatus } from "services/student/student";

const initialState = {
    status : {
        data: [],
        isLoading: false,
        isSuccess: false,
        errorMessage: ''
    }
}
export const completeStatusSlice = createSlice({
    name: 'completeStatus',
    initialState,
    extraReducers: {
        [completeStatus.pending]: (state) => {
            state.status.isLoading = true;
        },
        [completeStatus.fulfilled]: (state, { payload }) => {
            state.status.isLoading = false;
            state.status.isSuccess = true;
            state.status.data = payload;
        },
        [completeStatus.rejected]: (state, { payload }) => {
            state.status.isLoading = false;
            state.status.isSuccess = false;
            state.status.errorMessage = payload
        }
    }
})
export default completeStatusSlice.reducer;