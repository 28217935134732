import { createSlice } from "@reduxjs/toolkit";
import { getAllPayment } from "services/payment/payment";

const initialState = {
    payment : {
        data: [],
        isLoading: false,
        isSuccess: false,
        errorMessage: ''
    }
}
export const getAllPaymentSlice = createSlice({
    name: 'payment',
    initialState,
    extraReducers: {
        [getAllPayment.pending]: (state) => {
            state.payment.isLoading = true;
        },
        [getAllPayment.fulfilled]: (state, { payload }) => {
            state.payment.isLoading = false;
            state.payment.isSuccess = true;
            state.payment.data = payload;
        },
        [getAllPayment.rejected]: (state, { payload }) => {
            state.payment.isLoading = false;
            state.payment.isSuccess = false;
            state.payment.errorMessage = payload
        }
    }
})

export default getAllPaymentSlice.reducer;