import { createSlice } from "@reduxjs/toolkit";
import { postEmail } from "services/payment/paymentReceipt";
// import { updateStudent } from "services/student/student";

const initialState = {
    payment : {
        data: [],
        isLoading: false,
        isSuccess: false,
        errorMessage: ''
    }
}

export const postEmailSlice = createSlice({
    name: 'payment',
    initialState,
    extraReducers: {
        [postEmail.pending]: (state) => {
            state.payment.isLoading = true;
        },
        [postEmail.fulfilled]: (state, { payload }) => {
            state.payment.isLoading = false;
            state.payment.isSuccess = true;
            state.payment.data = payload;
        },
        [postEmail.rejected]: (state, { payload }) => {
            state.payment.isLoading = false;
            state.payment.isSuccess = false;
            state.payment.errorMessage = payload
        }
    }
})
export default postEmailSlice.reducer;