import React, {useEffect, useState} from "react";
import Grid from "@mui/material/Grid";
import TableComponent from "common/components/table";
import MobileList from "common/components/mobileList/MobileList";
import MDBox from "common/components/MDBox";
import {collection, deleteDoc, doc, getDocs} from "firebase/firestore";
import db from "services/firebase";
import MDButton from "common/components/MDButton";
import Icon from "@mui/material/Icon";
import Swal from "sweetalert2";

const columns = [
    { field: 'name', headerName: 'Name',accessor: "name" },
    { field: 'email', headerName: 'Email',accessor: "email" },
    { field: 'phone', headerName: 'Phone',accessor: "phone" },
    { field: 'message', headerName: 'Message',accessor: "message" },
    { field: 'action', headerName: 'Action',accessor: "action"}
];

function Acpt() {

    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    const [tableData, setTableData] = useState([]);

    useEffect(()=> {
        getDetails();
    },[]);

    async function getDetails() {
        const detailCol = getDocs(collection(db, "acpt_contacts"));
        const detailList = (await detailCol).docs.map(doc => doc.data());
        const id = (await detailCol).docs.map(doc => doc._key.path.segments[6]);
        const array = [];
        detailList.forEach((val, index)=> {
            array.push({
                name: val.name,
                email: val.mail,
                phone: val.contact,
                message: val.message,
                action: (
                    <MDButton variant="gradient" color="error" onClick={() => deleteRow(id[index])}>
                        <Icon fontSize="medium">delete</Icon>
                        {windowDimensions.width >= 400 && windowDimensions.width <= 768 && "Delete"}
                    </MDButton>
                ),
            })
        })
        setTableData(array);
    }

    function deleteRow (val) {
        Swal.fire({
            title: 'Are you sure?',
            text: "You will be able to delete this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                deleteData(val);
                getDetails();
            }
        })
    }

    async function deleteData(val) {
        await deleteDoc(doc(db, "acpt_contacts", val));
    }

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }
        {
            window.addEventListener('resize', handleResize);
            return () => window.removeEventListener('resize', handleResize);
        }
    },[windowDimensions]);

    function getWindowDimensions() {
        const {
            innerWidth: width,
            innerHeight: height
        } = window;
        return {
            width,
            height
        };
    }

    return (
        <MDBox pt={6} pb={3} >
            <div className="content">
                <Grid pt={6}>
                    {windowDimensions.width > 768 ?
                        <TableComponent data={tableData} columns={columns} />
                        :
                        <MobileList COLUMNS={columns} dataJson={tableData} searchKey={['name', 'phone']}/>
                    }
                </Grid>
            </div>
        </MDBox>
    )
}

export default Acpt;
