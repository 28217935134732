import { createSlice } from "@reduxjs/toolkit";
import { postEvent } from "services/event/event";

const initialState = {
    event : {
        data: [],
        isLoading: false,
        isSuccess: false,
        errorMessage: ''
    }
}
export const saveEventSlice = createSlice({
    name: 'saveEvent',
    initialState,
    extraReducers: {
        [postEvent.pending]: (state) => {
            state.event.isLoading = true;
        },
        [postEvent.fulfilled]: (state, { payload }) => {
            state.event.isLoading = false;
            state.event.isSuccess = true;
            state.event.data = payload;
        },
        [postEvent.rejected]: (state, { payload }) => {
            state.event.isLoading = false;
            state.event.isSuccess = false;
            state.event.errorMessage = payload
        }
    }
})
export default saveEventSlice.reducer;