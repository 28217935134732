import { createSlice } from "@reduxjs/toolkit";
import { getIntakeList } from "services/intake/intake";

const initialState = {
    intake : {
        data: [],
        isLoading: false,
        isSuccess: false,
        errorMessage: ''
    }
}

export const intakeSlice = createSlice({
    name: 'intake',
    initialState,
    extraReducers: {
        [getIntakeList.pending]: (state) => {
            state.intake.isLoading = true;
        },
        [getIntakeList.fulfilled]: (state, { payload }) => {
            state.intake.isLoading = false;
            state.intake.isSuccess = true;
            state.intake.data = payload;
        },
        [getIntakeList.rejected]: (state, { payload }) => {
            state.intake.isLoading = false;
            state.intake.isSuccess = false;
            state.intake.errorMessage = payload
        }
    }
})
export default intakeSlice.reducer;