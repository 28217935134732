import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { Box, Grid, InputAdornment } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import CircularProgress from "@mui/material/CircularProgress";
import RightNavbar from "common/components/RightNavbar";
import HeaderNavigator from "common/components/HeaderNavbar";
import MDButton from "common/components/MDButton";
import PaymentTableCard from "common/components/PaymentTableCard";
import MDBox from "common/components/MDBox";
import PaymentCard from "common/components/PaymentCard";
import { getAllPayment, addPayPayment } from "services/payment/payment";
import { getPaymentReceipt } from "services/payment/paymentReceipt";
import "./style.css";

function Payment () {

    const { id } = useParams();
    const dispatch = useDispatch();

    const [amount, setAmount] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [textFieldAllow, setTextFieldAllow] = useState(false);
    const [paymentButtonAllow, setPaymentButtonAllow] = useState(false);
    const [activeTab, setActiveTab] = useState("registration");

    const getPayment = useSelector((state) => state.getAllPaymentSlice.payment);
    const addPayment = useSelector((state) => state.addPayPaymentSlice.payment);
    const getPayReceipt = useSelector((state) => state.getPaymentReceiptSlice.payment);

    useEffect(() => {
        dispatch(getAllPayment(id));
    }, []);

    useEffect(()=> {
        if (getPayment.isSuccess && getPayment.data) {
            const regData = getPayment.data
            const ful = regData.registration_data.payment_data.filter((val)=> val.status === 'full-payment')
            if(activeTab === 'full-payment' || regData.amount_due === 0){
                setTextFieldAllow(true);
                if(activeTab === 'full-payment'){
                    setPaymentButtonAllow(false)
                }
                if (regData.registration_data.type !== 'scholership'){
                    const dis = (parseInt(regData.registration_data.amount)*parseInt(regData.registration_data.intake_data.discount))/100
                    setAmount(parseInt(regData.registration_data.amount)-dis)
                }else {
                    if(regData.amount_due === 0){
                        setAmount('')
                    }else {
                        setAmount(parseInt(regData.registration_data.amount))
                    }
                }
            }else {
                if(ful.length > 0){
                    setAmount('')
                    setTextFieldAllow(true);
                }else {
                    setAmount('')
                    setTextFieldAllow(false);
                }
            }
        }
    },[getPayment.data, activeTab])

    useEffect(() => {
        if (addPayment?.isSuccess && !addPayment?.isLoading && isLoading) {
            dispatch(getAllPayment(id));
            dispatch(getPaymentReceipt(addPayment?.data?.id));
            Swal.fire({
                icon: "success",
                title: "Index Added Successfully",
                toast: true,
                position: "top",
                showConfirmButton: false,
                timer: 3000,
            });
        }
    }, [addPayment]);

    useEffect(() => {
        if (getPayReceipt?.isSuccess && !getPayReceipt?.isLoading && isLoading) {
            setIsLoading(false);
            Swal.fire({
                title: "Index Successful",
                text: "If you don't like downloading this! please close",
                icon: "success",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, download it!",
            }).then((result) => {
                if (result.isConfirmed) {
                    handleClickPDF(getPayReceipt);
                }
            });
        }
    }, [getPayReceipt]);

    useEffect(()=> {
        if (getPayment.isSuccess && getPayment.data) {
            const regData = getPayment.data
            if (amount === '' || regData.amount_due < parseInt(amount)){
                setPaymentButtonAllow(false)
            }else {
                setPaymentButtonAllow(true);
            }
        }
    },[getPayment.data, amount])

    const handleClickPDF = (data) => {
        const fileURL = URL.createObjectURL(data?.data);
        window.open(fileURL);
    };

    const handleAmountChange = (e) => {
        const inputValue = e.target.value.replace(/[^0-9]/g, "");
        setAmount(inputValue);
    };

    const sendPayment = () => {
        const payload = {
            student_course_id: id,
            status: activeTab,
            payment: amount,
        };
        setIsLoading(true);
        dispatch(addPayPayment(payload));
    };

    const handleClick = () => {
        Swal.fire({
            title: "Are you sure?",
            text: "This action will proceed with the payment.",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, proceed!",
        }).then((result) => {
            if (result.isConfirmed) {
                sendPayment();
            }
        });
    };

    return (
        <MDBox sx={{ backgroundColor: "#FFF", borderRadius: "15px", p: 1 }}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={5.5} md={6} lg={8} xl={8}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                            {/* Subdivision 1 */}
                            <MDButton variant="contained" disabled className="Payment-name">
                                {" "}Index{" "}
                            </MDButton>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
                            {/* Subdivision 2 */}
                            <HeaderNavigator
                                activeTab={activeTab}
                                setActiveTab={setActiveTab}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={0} sx={{ mt: 1, mb: 1 }}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            {/* Subdivision 1 */}
                            <div className="PaymentCard">
                                <PaymentCard
                                    active={activeTab}
                                />
                            </div>
                        </Grid>
                    </Grid>

                    <Grid container spacing={2}>
                        {/* Left side */}
                        <Grid item xs={12} sm={6}>
                            <TextField
                                disabled={textFieldAllow}
                                label="Amount"
                                id="outlined-start-adornment"
                                defaultValue=""
                                value={amount}
                                onChange={handleAmountChange}
                                sx={{ mt: 2, width: { xs: "100%", lg: "70%", xl: "70%" } }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">Rs.</InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} container justifyContent="flex-end">
                            <Button
                                variant="contained"
                                className={'payment-button'}
                                color="success"
                                sx={{mt: 2, width: { xs: "100%", lg: "50%", xl: "50%" } }}
                                onClick={handleClick}
                                disabled={!paymentButtonAllow}
                                startIcon={isLoading && <CircularProgress sx={{color: '#efefef'}} size={20} />}
                            >
                                {isLoading ? "Processing..." : "Make Payment"}
                            </Button>
                        </Grid>
                    </Grid>
                    <hr className="payment-hr hr-hide" />
                    {/* Third part on the left side */}
                    <Box sx={{ height: "320px", overflowX: "auto" }}>
                        <PaymentTableCard/>
                    </Box>
                </Grid>

                {/* Right Side */}
                <Grid item xs={12} sm={6.5} md={6} lg={4} xl={4}>
                    <RightNavbar/>
                </Grid>
            </Grid>
        </MDBox>
    )
}

export default Payment;
