import React, {useEffect, useState} from "react";
import MDButton from "../MDButton";
import "./style.css";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import RightNavbarCard from "../RightNavbarCard";
import {emailIcon, phoneIcon, userIcon, idIcon} from "config/images";
import {getImageUrl} from "common/utils/CommonFunctions";
import {useSelector} from "react-redux";

const RightNavbar = () => {

    const [payData, setPayData] = useState();
    const getPayment = useSelector((state) => state.getAllPaymentSlice.payment);

    useEffect(() => {
        if (getPayment.isSuccess && getPayment.data) {
            const registrationData = getPayment?.data?.registration_data;
            setPayData(registrationData);
        }
    }, [getPayment.data]);

    const data = [
        {
            icon: userIcon,
            name: payData?.student_data?.name,
        },
        {
            icon: idIcon,
            name: payData?.student_data?.nic,
        },
        {
            icon: emailIcon,
            name: payData?.student_data?.email,
        },
        {
            icon: phoneIcon,
            name: payData?.student_data?.contact_no,
        },
    ];

    return (
        <Card sx={{backgroundColor: "#fff", boxShadow: "0px 2px 1px  #bdbdbd"}}>
            <CardContent>
                <img
                    src={getImageUrl(payData?.course_data?.image)}
                    alt="course-image"
                    width="100%"
                    height="100%"
                    className="RightNavbar-image"
                />
                <Typography variant="body2" sx={{mt: 5}}>
                    <MDButton variant="contained" disabled className="rightBar-name">
                        {" "}{payData?.course_data?.title}{" "}
                    </MDButton>
                </Typography>
                {data.map((item, index) => (
                    <RightNavbarCard item={item} key={index}/>
                ))}
            </CardContent>
        </Card>
    );
};

export default RightNavbar;
