import React, {useEffect, useState} from "react";
import ModalComponent from "common/components/Model";
import MDBox from "common/components/MDBox";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import MDButton from "common/components/MDButton";
import Switch from "@mui/material/Switch";
import Divider from "@mui/material/Divider";
import {useDispatch, useSelector} from "react-redux";
import {
    getStudentDetails,
    getStudentList,
    postStudent,
    updateStudent,
} from "services/student/student";
import Swal from "sweetalert2";

const Toast = Swal.mixin({
    toast: true,
    position: "top",
    zIndex: 10,
    showConfirmButton: false,
    timer: 2000,
    onOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
});

const AddStudentModel = ({
                             setShowModal,
                             showModal,
                             updateData,
                             sName,
                             sId,
                             sNumber,
                             sEmail,
                             sNic,
                             id,
                             sStatus,
                         }) => {
    const dispatch = useDispatch();
    const student = useSelector((state) => state.saveStudentSlice.student);
    const studentUpdate = useSelector(
        (state) => state.UpdateStudentSlice.student
    );
    const [saveLoader, setSaveLoader] = useState(false);
    const [updateLoader, setUpdateLoader] = useState(false);
    const [name, setName] = useState(updateData ? sName : "");
    const [number, setNumber] = useState(updateData ? sNumber : "");
    const [nic, setNic] = useState(updateData ? sNic : "");
    const [email, setEmail] = useState(updateData ? sEmail : "");
    const [status, setStatus] = useState(updateData ? sStatus : "");
    const [nicError, setNicError] = useState("");
    const [contactNoError, setContactNoError] = useState("");
    const [emailError, setEmailError] = useState("");

    const handleNicNoChange = (e) => {
        setNic(e.target.value);
        if (!/^([0-9]{9}[x|X|v|V]|[0-9]{12})$/.test(e.target.value)) {
            setNicError("Invalid NIC number");
        } else {
            setNicError("");
        }
    };
    const handleContactNoChange = (e) => {
        setNumber(e.target.value);
        if (!/^(\+94|0)\d{9}$/.test(e.target.value)) {
            setContactNoError("Invalid mobile number");
        } else {
            setContactNoError("");
        }
    };
    const handleEmailChange = (e) => {
        setEmail(e.target.value);
        if (
            !/^([a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/.test(e.target.value)
        ) {
            setEmailError("Invalid Email Address");
        } else {
            setEmailError("");
        }
    };

    const saveStudent = () => {
        const form = new FormData();
        form.append("name", name);
        form.append("contact_no", number);
        form.append("nic", nic);
        form.append("email", email);
        setSaveLoader(true);
        dispatch(postStudent(form));
    };
    const handleUpdateStudent = () => {
        const form = new FormData();
        form.append("name", name);
        form.append("contact_no", number);
        form.append("nic", nic);
        form.append("email", email);
        form.append("status", status);

        setUpdateLoader(true);
        dispatch(updateStudent({id: id, studentDetailsUpdate: form}));
    };

    const handleStatusChange = (e) => {
        const newStatus = e.target.checked ? "active" : "de-active";
        setStatus(newStatus);
    };

    useEffect(() => {
        if (updateLoader) {
            if (studentUpdate.isSuccess && !studentUpdate.isLoading) {
                setUpdateLoader(false);
                setShowModal(false);
                dispatch(getStudentList());
                dispatch(getStudentDetails({id: id}));
                Toast.fire({
                    icon: "success",
                    title: "Student Updated Successfully",
                });
            } else if (studentUpdate.errorMessage === "Request failed with status code 403" && !studentUpdate.isLoading) {
                setUpdateLoader(false);
                Toast.fire({
                    icon: "error",
                    title: "NIC already exist",
                });
            }
        }
    }, [studentUpdate]);

    useEffect(() => {
        if (saveLoader) {
            if (student.isSuccess && !student.isLoading) {
                Toast.fire({
                    icon: "success",
                    title: "Student Added Successfully",
                });
                setSaveLoader(false);
                setShowModal(false);
                dispatch(getStudentList());
            } else if (student.errorMessage === "Request failed with status code 403" && !student.isLoading) {
                Toast.fire({
                    icon: "error",
                    title: "NIC already exist",
                });
                setSaveLoader(false);
            }
        }
    }, [student]);

    const clearText = () => {
        setName("");
        setNumber("");
        setNic("");
        setEmail("");
        setStatus("");
    };

    return (
        <ModalComponent
            setShowModal={setShowModal}
            showModal={showModal}
            maxWidth={"md"}
        >
            <MDBox
                pb={3}
                sx={{
                    padding: {
                        xs: 2,
                        md: 6,
                    },
                    paddingTop: {
                        xs: 6,
                    },
                }}
            >
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={9} md={8} xl={8}>
                        <TextField
                            id="outlined-helperText"
                            label="Name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} xl={6}>
                        <TextField
                            id="outlined-helperText"
                            label="NIC"
                            value={nic}
                            onChange={handleNicNoChange}
                            fullWidth={true}
                            error={nicError !== ""}
                            helperText={nicError}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} xl={6}>
                        <TextField
                            id="outlined-helperText"
                            label="E-mail"
                            value={email}
                            //onChange={e => setEmail(e.target.value)}
                            onChange={handleEmailChange}
                            fullWidth
                            error={emailError !== ""}
                            helperText={emailError}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} xl={6}>
                        <TextField
                            id="outlined-helperText"
                            label="Number"
                            value={number}
                            fullWidth
                            onChange={handleContactNoChange}
                            error={contactNoError !== ""}
                            helperText={contactNoError}
                        />
                    </Grid>
                    {updateData && (
                        <Grid item xs={12} sm={6} md={6} xl={6}>
                            <Grid>
                                Student Status
                                <Switch
                                    checked={status === "active"}
                                    onChange={handleStatusChange}
                                    color="secondary"
                                />
                            </Grid>
                        </Grid>
                    )}
                    {updateData && (
                        <Grid item xs={12} sm={6} md={6} xl={6}>
                            <Divider/>
                        </Grid>
                    )}
                    <Grid
                        item
                        xs={12}
                        md={6}
                        xl={6}
                        sx={{
                            display: "flex",
                            flexDirection: {xs: "column", sm: "row"},
                            flexWrap: "nowrap",
                        }}
                    >
                        <Grid
                            item
                            xs={12}
                            md={6}
                            xl={6}
                            sx={{
                                paddingRight: {xs: 0, sm: "8px"},
                                paddingBottom: {xs: "8px", sm: 0},
                            }}
                        >
                            {!updateData && (
                                <MDButton
                                    variant="outlined"
                                    color="secondary"
                                    fullWidth
                                    disabled={updateData}
                                    onClick={() => {
                                        clearText();
                                    }}
                                >
                                    Clear
                                </MDButton>
                            )}
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            md={6}
                            xl={6}
                            sx={{
                                paddingLeft: {xs: 0, sm: "8px"},
                                paddingTop: {xs: "8px", sm: 0},
                            }}
                        >
                            <MDButton
                                variant="gradient"
                                fullWidth
                                color="info"
                                disabled={
                                    name === "" ||
                                    nic === "" ||
                                    number === "" ||
                                    email === "" ||
                                    nicError ||
                                    contactNoError ||
                                    emailError || student.isLoading || studentUpdate.isLoading
                                }
                                onClick={
                                    updateData
                                        ? () => {
                                            handleUpdateStudent();
                                        }
                                        : () => {
                                            saveStudent();
                                        }
                                }
                            >
                                {updateData ? "Update" : "Add Student"}
                            </MDButton>
                        </Grid>
                    </Grid>
                </Grid>
            </MDBox>
        </ModalComponent>
    );
};
export default AddStudentModel;
