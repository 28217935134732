import MDBox from "common/components/MDBox";
import Grid from "@mui/material/Grid";
import MDButton from "common/components/MDButton";
import Icon from "@mui/material/Icon";
import React, {useEffect, useState} from "react";
import AddIntakeModel from "component/AddIntakeModel";
import TableComponent from "common/components/table";
import MobileList from "common/components/mobileList/MobileList";
import Switch from "@mui/material/Switch";
import "./style.css"
import {useDispatch, useSelector} from "react-redux";
import {deleteIntake, getIntakeList} from "services/intake/intake";
import Swal from "sweetalert2";

const Toast = Swal.mixin({
    toast: true,
    position: 'top',
    zIndex:10,
    showConfirmButton: false,
    timer: 2000,
    onOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
});

const columns = [
    { field: 'id', headerName: 'Id',accessor: "id" },
    { field: 'intake', headerName: 'Intake',accessor: "intake" },
    { field: 'courseFee', headerName: 'Course Fee',accessor: "courseFee" },
    { field: 'discount', headerName: 'Discount',accessor: "discount" },
    { field: 'startDate', headerName: 'Start Date',accessor: "startDate" },
    { field: 'status', headerName: 'Status',accessor: "status" },
    { field: 'action', headerName: 'Action',accessor: "action"}
];

function Intake() {
    const dispatch = useDispatch()
    const [tableData, setTableData] = useState([])
    const [deleteLoader, setDeleteLoader] = useState(false);
    const [popUp, setPop] = useState(false);
    const [updateIntake, setUpdateIntake] = useState(false)
    const [selectedIntake, setSelectedIntake]=useState({})
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    const intake = useSelector(state => state.intakeSlice.intake);
    const intakeDelete = useSelector(state => state.deleteIntakeSlice.intake);

    const updateIntakes = (data) => {
        setUpdateIntake(true);
        setSelectedIntake(data);
    }

    const handleDelete = (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: 'Do you want to delete this intake?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.isConfirmed ) {
                setDeleteLoader(true)
                dispatch(deleteIntake(id));
            }
        });
    };

    function getWindowDimensions() {
        const {
            innerWidth: width,
            innerHeight: height
        } = window;
        return {
            width,
            height
        };
    }

    useEffect(() => {
        dispatch(getIntakeList());
    }, []);


    useEffect(()=>{
        if (intake.isSuccess){
            const data=intake.data;
            const array=[];
            data.forEach((val)=>{
                array.push(
                    {
                        id:val.id,
                        intake:val.intake,
                        courseFee:val.course_fee,
                        discount:val.discount+"%",
                        startDate:val.started_date,
                        status:(<Switch checked={val.current_active_status==="1"}/>),
                        action:  (
                            <div className="my-class1">
                                <MDButton
                                    variant="gradient"
                                    color="success"
                                    onClick={()=> updateIntakes(val)}
                                >
                                    <Icon fontSize="medium">edit</Icon>
                                    {windowDimensions.width >= 400 && windowDimensions.width <= 768 && "Edit"}
                                </MDButton>
                                <MDButton variant="gradient" color="error" onClick={() => handleDelete(val.id)}>
                                    <Icon fontSize="medium">delete</Icon>
                                    {windowDimensions.width >= 400 && windowDimensions.width <= 768 && "Delete"}
                                </MDButton>

                            </div>
                        ),
                }
                )
            })
            setTableData(array)
        }
    },[intake.data])

    useEffect(() => {
        if (deleteLoader){
            setDeleteLoader(false)
            if (intakeDelete.isSuccess){
                dispatch(getIntakeList())
                Toast.fire({
                    icon: 'success',
                    title: 'Intake Deleted Successfully'
                });
            } else if (intakeDelete.isError) {
                Toast.fire({
                    icon: 'error',
                    title: 'Intake Failed to Delete'
                });
            }
        }
    }, [intakeDelete.data]);

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }
        {
            window.addEventListener('resize', handleResize);
            return () => window.removeEventListener('resize', handleResize);
        }
    },[windowDimensions]);

    return(
        <MDBox pt={6} pb={3}>
             <Grid container spacing={6} justifyContent="flex-end">
                 <Grid item>
                     <MDButton variant="gradient" color="info" onClick={() => {setPop(true)}}>
                     <div className="Intake_button-icon">
                         <Icon fontSize="large" sx={{marginRight:1,marginBottom:0.2}}>
                             control_point
                         </Icon>
                         &nbsp;Add New Intake
                         </div>
                     </MDButton>
                 </Grid>
             </Grid>
             <Grid pt={6}>
                 {windowDimensions.width > 768 ?
                     <TableComponent data={tableData} columns={columns} />
                     :
                     <MobileList COLUMNS={columns} dataJson={tableData} searchKey={['intake', 'startDate']}/>
                 }
             </Grid>
             {popUp &&
                 <AddIntakeModel setShowModal={setPop} showModal={popUp}/>
             }
            {updateIntake &&
                <AddIntakeModel setShowModal={setUpdateIntake} showModal={updateIntake} assignData={selectedIntake} updateData={true}/>
            }
         </MDBox>
    )
}
export default Intake;
