import TableComponent from "common/components/table";
import MobileList from "common/components/mobileList/MobileList";
import Grid from "@mui/material/Grid";
import React, {useEffect, useState} from "react";
import Switch from "@mui/material/Switch";
import MDButton from "common/components/MDButton";
import Icon from "@mui/material/Icon";
import MDBox from "common/components/MDBox";
import AddReviewModel from "component/AddReviewModel";
import Swal from "sweetalert2";
import {useDispatch, useSelector} from "react-redux";
import {deleteReview, getReviewList} from "services/review/review";
import ReadMoreReact from 'read-more-react';
import {getCourseList} from "services/course/course";

const columns = [{field: 'id', headerName: 'Id', accessor: "id"}, {
    field: 'name',
    headerName: 'Name',
    accessor: "name"
}, {field: 'course', headerName: 'Course', accessor: "course"}, {
    field: 'review',
    headerName: 'Review',
    accessor: "review"
}, {field: 'status', headerName: 'Status', accessor: "status"}, {
    field: 'action',
    headerName: 'Action',
    accessor: "action"
}];

const Toast = Swal.mixin({
    toast: true, position: 'top-right', showConfirmButton: false, timer: 2000, onOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
});

function Review() {

    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    const [popUp, setPopUp] = useState(false);
    const [update, setUpdate] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [currentReview, setCurrentReview] = useState({});
    const [deleteLoader, setDeleteLoader] = useState(false);
    const dispatch = useDispatch();
    const reviews = useSelector((state) => state.getReviewListSlice.reviews)
    const deletedReview = useSelector((state) => state.deleteReviewSlice.review)

    const updateReview = (review) => {
        setCurrentReview(review)
        setUpdate(true)
        setPopUp(true)
    }

    function getWindowDimensions() {
        const {
            innerWidth: width, innerHeight: height
        } = window;
        return {
            width, height
        };
    }

    useEffect(() => {
        dispatch(getCourseList())
    }, [])

    const reviewDelete = (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: " Do you want delete this review ?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(deleteReview(id))
                setDeleteLoader(true)
            }
        });
    };

    useEffect(() => {
        if (deleteLoader) {
            if (deletedReview.isSuccess && !deletedReview.isLoading) {
                dispatch(getReviewList())
                setDeleteLoader(false)
                Toast.fire({
                    icon: 'success', title: 'Review Deleted Successfully'
                });
            } else if (deletedReview.isError && !deletedReview.isLoading) {
                setDeleteLoader(false)
                Toast.fire({
                    icon: 'error', title: 'Failed to delete Review'
                });
            }
        }
    }, [deletedReview]);

    useEffect(() => {
        dispatch(getReviewList())
    }, [])

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }
        {
            window.addEventListener('resize', handleResize);
            return () => window.removeEventListener('resize', handleResize);
        }
    }, [windowDimensions]);

    useEffect(() => {
        if (reviews.isSuccess && !reviews.isLoading) {
            const data = reviews.data;
            const array = [];
            data.forEach((review) => {
                array.push({
                    id: review.id,
                    name: review.name,
                    course: review.course,
                    review: (<ReadMoreReact text={review.review} />),
                    status: <Switch checked={review.status === "active"} color="secondary" disabled={true} />,
                    action: (<div className="my-class1">
                        {windowDimensions.width < 400 || windowDimensions.width > 768 ?
                            <MDButton size="large" variant="gradient" color="success" iconOnly={true}
                                      sx={{mr: 1}}
                                      onClick={() => updateReview(review)}>
                                <Icon fontSize="large">edit</Icon>
                            </MDButton> : <MDButton variant="gradient" color="success"
                                                    onClick={() => updateReview(review)}>
                                <Icon fontSize="large">edit</Icon>
                                Edit
                            </MDButton>}
                        {windowDimensions.width < 400 || windowDimensions.width > 768 ?
                            <MDButton size="large" variant="gradient" color="error"
                                      iconOnly={true} onClick={() => reviewDelete(review.id)}>
                                <Icon fontSize="large">delete</Icon>
                            </MDButton> : <MDButton variant="gradient" color="error"
                                                    className="button" onClick={() => reviewDelete(review.id)}>
                                <Icon fontSize="large">delete</Icon>
                                Delete
                            </MDButton>}
                    </div>)
                })
            })
            setTableData(array)
        }
    }, [reviews.data])

    return (<MDBox pt={6} pb={3}>
            <div className="content">
                <Grid container spacing={6} justifyContent="flex-end">
                    <Grid item>
                        <MDButton variant="gradient"
                                  color="info"
                                  onClick={() => {
                                      setPopUp(true)
                                      setUpdate(false)
                                  }}>
                            <Icon fontSize="large" sx={{marginRight: 1, marginBottom: 0.2}}>
                                control_point
                            </Icon>
                            Add New Review
                        </MDButton>
                    </Grid>
                </Grid>
                <Grid item xs={12} lg={12} md={12} sm={12} xl={12} pt={6}>
                    {windowDimensions.width > 830 ? <TableComponent data={tableData} columns={columns}/> :
                        <MobileList COLUMNS={columns} dataJson={tableData} searchKey={['name', 'course']}/>}
                </Grid>
            </div>
            {popUp && <AddReviewModel showModal={popUp} setShowModal={setPopUp} updateData={update}
                                      assignData={currentReview}></AddReviewModel>}
        </MDBox>)
}

export default Review;