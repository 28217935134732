import React, {useEffect, useState} from "react";
import ModalComponent from "common/components/Model";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import MDBox from "common/components/MDBox";
import MDButton from "common/components/MDButton";
import "./style.css";
import ImageUploader from "common/components/ImageUploader";
import Switch from "@mui/material/Switch";
import {getBLogList, postBlog, updateBlogs} from "services/blog/blog";
import {useDispatch, useSelector} from "react-redux";
import Swal from "sweetalert2";
import CommonSelect from "common/components/CustomSelect";
import {getImageUrl, onImageEdit} from "common/utils/CommonFunctions";
import blog from "../../pages/blog";

const Toast = Swal.mixin({
    toast: true,
    position: "top",
    showConfirmButton: false,
    timer: 2000,
    onOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
});

const AddBlogModel = ({setShowModal, showModal, updateData, assignData}) => {
    const [imageUrl, setImageUrl] = useState(
        updateData
            ? getImageUrl(assignData.image)
            : ""
    );
    const [postImage, setPostImage] = useState();
    const [date, setDate] = useState(updateData ? assignData.date : "");
    const [title, setTitle] = useState(updateData ? assignData.title : "");
    const [blog_content, setBlogContent] = useState(
        updateData ? assignData.blog_content : ""
    );
    const [tag, setTag] = useState(updateData ? assignData.tag : "");
    const [status, setStatus] = useState(updateData ? assignData.status : "");
    const [saveLoader, setSaveLoader] = useState(false);
    const [updateLoader, setUpdateLoader] = useState(false);
    const dispatch = useDispatch();
    const blog = useSelector((state) => state.SaveBlogSlice.blog);
    const BlogUpdate = useSelector((state) => state.UpdateBlogSlice.blog);
    const [isValidImage, setIsValidImage] = useState(true);

    const handlePostImage = (val) => {
        const img = new Image();
        img.onload = function () {
            if (img.width === 373 && img.height === 252) {
                setIsValidImage(true);
                setPostImage(val);
            } else {
                setIsValidImage(false);
            }
        };
        img.src = URL.createObjectURL(val);
    };

    const options = [
        {label: "Admin", value: "Admin"},
        {label: "Officer", value: "Officer"},
    ];
    const saveBlog = () => {
        const form = new FormData();
        form.append("image", postImage);
        form.append("date", date);
        form.append("title", title);
        form.append("blog_content", blog_content);
        form.append("tag", tag);
        setSaveLoader(true);
        dispatch(postBlog(form));
    };
    const handleUpdateBlog = () => {
        const form = new FormData();
        form.append("image", postImage);
        form.append("date", date);
        form.append("title", title);
        form.append("blog_content", blog_content);
        form.append("tag", tag);
        form.append("status", status);

        setUpdateLoader(true);
        dispatch(updateBlogs({id: assignData.id, updatedBlog: form}));
    };
    const handleStatusChange = (e) => {
        const newStatus = e.target.checked ? "active" : "inactive";
        setStatus(newStatus);
    };

    useEffect(() => {
        if (updateData) {
            onImageEdit(
                getImageUrl(assignData.image)
            ).then((file) => setPostImage(file));
        }
    }, []);

    useEffect(() => {
        if (saveLoader) {
            setSaveLoader(false);
            if (blog.isSuccess && !blog.isLoading) {
                clearText();
                setShowModal(false);
                dispatch(getBLogList());
                Toast.fire({
                    icon: "success",
                    title: "Blog Posted Successfully",
                });
            } else if (blog.isError) {
                Toast.fire({
                    icon: "error",
                    title: "Invalid Input",
                });
            }
        }
    }, [blog.data]);

    useEffect(() => {
        if (updateLoader) {
            setUpdateLoader(false);
            if (BlogUpdate.isSuccess && !BlogUpdate.isLoading) {
                setShowModal(false);
                dispatch(getBLogList());
                Toast.fire({
                    icon: "success",
                    title: "Blog Updated Successfully",
                });
            } else if (BlogUpdate.isError) {
                Toast.fire({
                    icon: "error",
                    title: "Invalid Input",
                });
            }
        }
    }, [BlogUpdate.data]);

    const clearText = () => {
        setTitle("");
        setBlogContent("");
        setTag("");
        setPostImage("");
        setDate("");
        setStatus("");
    };
    return (
        <ModalComponent
            setShowModal={setShowModal}
            showModal={showModal}
            maxWidth={"md"}
        >
            <MDBox
                pb={3}
                sx={{
                    padding: {
                        xs: 2,
                        md: 4,
                    },
                    paddingTop: {
                        xs: 6,
                    },
                    mt: 2,
                }}
            >
                <Grid container spacing={2}>
                    {/* First row */}
                    <Grid item xs={12} sm={4} md={4} lg={5}>
                        <TextField
                            id="outlined-helperText"
                            label="Title"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            sx={{
                                width: {
                                    xs: "100%",
                                    sm: "100%",
                                    md: "100%",
                                },
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={4} lg={3}>
                        <CommonSelect
                            id="outlined-helperText"
                            options={options}
                            value={tag}
                            onChange={(e) => setTag(e.target.value)}
                            label="Tag"
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={4} lg={4}>
                        <TextField
                            id="outlined-helperText"
                            type="date"
                            value={date}
                            onChange={(e) => setDate(e.target.value)}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            sx={{
                                width: {
                                    xs: "100%",
                                    sm: "100%",
                                    md: "100%",
                                },
                            }}
                        />
                    </Grid>
                    {/* Second row*/}
                    <Grid item xs={12} sm={6} md={5} lg={5}>
                        <TextField
                            id="outlined-multiline-static"
                            label="Content"
                            name="blog_content"
                            value={blog_content}
                            onChange={(e) => setBlogContent(e.target.value)}
                            multiline
                            rows={6}
                            sx={{
                                width: {
                                    xs: "100%",
                                    md: "100%",
                                },
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={5} lg={5} >
                        <ImageUploader
                            postImage={handlePostImage}
                            imageUrl={imageUrl}
                            onImageSelect={setImageUrl}
                            width="100%"
                            height="150px"
                        />
                        {updateData && (
                            <Grid>
                                Status
                                <Switch
                                    color="error"
                                    name="status"
                                    checked={status === "active"}
                                    onChange={handleStatusChange}
                                />
                            </Grid>
                        )}
                        {!isValidImage && (
                            <div className="image-upload">You are added invalid image</div>
                        )}
                    </Grid>

                    {/* Third row */}
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} md={6} xl={6}>
                                <MDButton
                                    variant="outlined"
                                    color="secondary"
                                    disabled={updateData}
                                    sx={{
                                        width: {
                                            xs: "100%",
                                            md: "100%",
                                        },
                                    }}
                                    onClick={() => {
                                        clearText();
                                    }}
                                >
                                    Clear
                                </MDButton>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} xl={6}>
                                <MDButton
                                    variant="gradient"
                                    disabled={
                                        title === "" ||
                                        date === "" ||
                                        tag === "" ||
                                        blog_content === "" ||
                                        !imageUrl ||
                                        !isValidImage || blog.isLoading || BlogUpdate.isLoading
                                    }
                                    sx={{
                                        width: {
                                            xs: "100%",
                                            md: "100%",
                                        },
                                    }}
                                    color={updateData ? "success" : "info"}
                                    onClick={
                                        updateData
                                            ? () => {
                                                handleUpdateBlog();
                                            }
                                            : () => {
                                                saveBlog();
                                            }
                                    }
                                >
                                    {updateData ? "Update" : "Add Blog"}
                                </MDButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </MDBox>
        </ModalComponent>
    );
};
export default AddBlogModel;
