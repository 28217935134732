import React, {useEffect, useState} from "react";
import Grid from "@mui/material/Grid";
import MDBox from "common/components/MDBox";
import image1 from "assets/images/studentTwo.png"
import image2 from "assets/images/scholarship.png";
import image3 from "assets/images/studentOne.png";
import image4 from "assets/images/graduation.png"
import Switch from "@mui/material/Switch";
import MDButton from "common/components/MDButton";
import Icon from "@mui/material/Icon";
import TableComponent from "common/components/table";
import MobileList from "common/components/mobileList/MobileList";
import "./style.css"
import DashBoardCards from "common/components/DashBoardCards";
import {useDispatch, useSelector} from "react-redux";
import {getStudentCount, getStudentList} from "services/student/student";
import {getAllIntakeList} from "services/intake/intake";
import {useNavigate} from "react-router-dom";
import {getCourseList} from "services/course/course";

const columns = [
    {field: 'id', headerName: 'Id', accessor: "id"},
    {field: 'name', headerName: 'Name', accessor: "name"},
    {field: 'nic', headerName: 'NIC', accessor: "nic"},
    {field: 'view', headerName: 'View', accessor: "view"}
]

const intakeColumns = [
    {field: 'id', headerName: 'Id', accessor: "id"},
    {field: 'intake', headerName: 'Intake', accessor: "intake"},
    {field: 'studentCount', headerName: 'Student Count', accessor: "studentCount"},
    {field: 'view', headerName: 'View', accessor: "view"}
];

function Dashboard() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const student = useSelector(state => state.studentSlice.student);
    const intake = useSelector(state => state.allDetailIntakeSlice.intake);
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    const [studentTableData, setStudentTableData] = useState([])
    const [intakeTableData, setIntakeTableData] = useState([])
    const count = useSelector(state => state.studentCountSlice.count);
    const [courseCount, setCourseCount] = useState(0)
    const [studentCount, setStudentCount] = useState(0)
    const [completeStudentCount, setCompleteStudentCount] = useState(0)
    const [lectures, setLectures] = useState(0)
    const [pendingIntake, setPendingIntake] = useState("1")
    const [activeStudent, setActiveStudent] = useState("active")
    const data = [
        {
            image: image4,
            title: "Student",
            noOfStudents: studentCount,
            bgColourmain: "#253BFF",
            bgColourSec: "#ABB3FF"
        },
        {
            image: image2,
            title: "Complete Student",
            noOfStudents: completeStudentCount,
            bgColourmain: "#FFC146",
            bgColourSec: "#FFE1A6"
        },
        {
            image: image3,
            title: "Lectures",
            noOfStudents: lectures,
            bgColourmain: "#F55254",
            bgColourSec: "#FF9E9F"
        }
        , {
            image: image1,
            title: "Course",
            noOfStudents: courseCount,
            bgColourmain: "#4D4E8D",
            bgColourSec: "#A6A7E5"
        }
    ];

    useEffect(() => {
        dispatch(getStudentList());
        dispatch(getAllIntakeList());
        dispatch(getCourseList());
        dispatch(getStudentCount());
    }, []);

    useEffect(() => {
        if (student.isSuccess) {
            const data = student.data
            setStudentCount(data.length)
            const array = []
            data.forEach((val) => {
                if (val.status === activeStudent ){
                    array.push({
                        id: val.id,
                        name: val.name,
                        nic: val.nic,
                        view: (<MDButton variant="gradient"  sx={{marginBottom:1}} onClick={() =>
                            navigate(`/student-details/${val.id}`)
                        } iconOnly={true}>
                            <Icon fontSize="large">visibility</Icon>
                        </MDButton>)
                    })
                }
            })
            setStudentTableData(array);

        }

    }, [student.data,activeStudent])

    useEffect(()=>{
        if (intake.isSuccess) {
            const data = intake.data
            const array = []
            data.forEach((val) => {
                if (val.current_active_status === pendingIntake) {
                    array.push({
                        id: array.length+1,
                        intake: val.intake,
                        studentCount: val.student_count,
                        view: (<MDButton variant="gradient"   onClick={() =>
                            navigate("/course")
                        } iconOnly={true}>
                            <Icon fontSize="large">visibility</Icon>
                        </MDButton>)
                    })
                }
            })
            setIntakeTableData(array);
        }
    },[intake.data,pendingIntake])

    useEffect(()=>{
        if (count.isSuccess){
            const data = count.data
            setStudentCount(data.students)
            setCourseCount(data.courses)
            setCompleteStudentCount(data.completed_student)
            setLectures(data.lectures)
        }
    },[count.data])

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }
        {
            window.addEventListener('resize', handleResize);
            return () => window.removeEventListener('resize', handleResize);
        }
    }, [windowDimensions]);

    function getWindowDimensions() {
        const {
            innerWidth: width,
            innerHeight: height
        } = window;
        return {
            width,
            height
        };
    }

    const handelIntakeSwitch = (event) =>{
        event? setPendingIntake("1") : setPendingIntake("0");
    }

    const handelStudentSwitch = (event) =>{
        event? setActiveStudent("active") : setActiveStudent("de-active");
    }

    return (
        <MDBox pt={3} pb={3}>
            <Grid container gap={0}>
                {data.map((item, index) => (
                    <DashBoardCards item={item} key={index}/>
                ))}
                <Grid container gap={3} pt={3}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={6.5} className="grid1">
                        <div>
                            <Grid container columns={12}>
                                <Grid item xs={6}>
                                    <h5 id="header">Students</h5>
                                </Grid>
                                <Grid item xs={6}>
                                    <p id="headerTitle1">Inactive <Switch defaultChecked color="secondary" onChange={(event)=>handelStudentSwitch(event.target.checked)}/>Active</p>
                                </Grid>
                            </Grid>
                            <div>
                                {windowDimensions.width > 768 ?
                                    <TableComponent data={studentTableData} columns={columns}/>
                                    :
                                    <MobileList COLUMNS={columns} dataJson={studentTableData}
                                                searchKey={['id', 'studentId']}/>
                                }

                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={5.1} className="grid1">
                        <div>
                            <Grid container columns={12}>
                                <Grid item xs={6}>
                                    <h5 id="header">Intake</h5>
                                </Grid>
                                <Grid item xs={6}>
                                    <p id="headerTitle1">Inactive <Switch defaultChecked color="secondary" onChange={(event)=>handelIntakeSwitch(event.target.checked)}/>Active</p>
                                </Grid>
                            </Grid>
                            <div>
                                {windowDimensions.width > 768 ?
                                    <TableComponent data={intakeTableData} columns={intakeColumns}/>
                                    :
                                    <MobileList COLUMNS={intakeColumns} dataJson={intakeTableData}
                                                searchKey={['id', 'intake']}/>
                                }
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </Grid>
        </MDBox>
    );
}

export default Dashboard;
