// Logo
export const pageLoading = require("assets/images/book-loader.gif");
export const navLogo = require("assets/images/logo.png");
export const defaultLoad = require("assets/images/image.png");
export const defaultLoadMini = require("assets/images/imagemini.png");
//payment
export const emailIcon = require("assets/images/email.png");
export const idIcon = require("assets/images/id-card.png");
export const phoneIcon = require("assets/images/phone.png");
export const userIcon = require("assets/images/user.png");
export const miniCorrect = require("assets/images/mimicorrect.png");
export const pending = require("assets/images/pending.png");
export const eye = require("assets/images/eye.png");
export const correct = require("assets/images/correct.png");
export const online = require("assets/images/online.png");
export const AMAD = require("assets/images/AMAD.jpg");
export const transaction = require("assets/images/transaction.png");


//student
export const certificateLogo = require("assets/images/certificate-90 1.png");
