import {createSlice} from "@reduxjs/toolkit";
import {updateRegisteredCourse} from "../../../services/student/student";

const initialState = {
    registration : {
        data: [],
        isLoading: false,
        isSuccess: false,
        errorMessage: ''
    }
}

export const updateRegisteredCourseSlice = createSlice({
    name: 'updateRegisteredCourse',
    initialState,
    extraReducers: {
        [updateRegisteredCourse.pending]: (state) => {
            state.registration.isLoading = true;
        },
        [updateRegisteredCourse.fulfilled]: (state, { payload }) => {
            state.registration.isLoading = false;
            state.registration.isSuccess = true;
            state.registration.data = payload;
        },
        [updateRegisteredCourse.rejected]: (state, { payload }) => {
            state.registration.isLoading = false;
            state.registration.isSuccess = false;
            state.registration.errorMessage = payload
        }
    }
})

export default updateRegisteredCourseSlice.reducer