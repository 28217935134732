import React, {useEffect, useState} from "react";
import {Card, CardContent, Typography} from "@mui/material";
import {useSelector} from "react-redux";
import {miniCorrect, pending} from "config/images";
import "./style.css";

export default function PaymentCard({active}) {

    const [total, setTotal] = useState(0);
    const [amountDue, setAmountDue] = useState(0);
    const [amountPaid, setAmountPaid] = useState(0);

    const getPayment = useSelector((state) => state.getAllPaymentSlice.payment);

    useEffect(()=> {
        if (getPayment.isSuccess && getPayment.data) {
            const regData = getPayment.data
            setTotal(parseInt(regData.registration_data.amount))
            if (active === 'full-payment' && regData.registration_data.type !== 'scholership'){
                const dis = (parseInt(regData.registration_data.amount)*parseInt(regData.registration_data.intake_data.discount))/100
                setAmountDue(parseInt(regData.registration_data.amount)-dis)
            }else {
                setAmountDue(parseInt(regData.amount_due))
            }
            setAmountPaid(parseInt(regData.amount_paid))
        }
    },[getPayment.data, active])

    return (
        <div className="PaymentCard-flex">
            <Card className="PaymentName-card"
                  sx={{
                    mr: 0.5,
                    ml: 0.5,
                    display: "flex",
                    borderRadius: 0,
                    width: "240px",
                    bgcolor: "#F4F4F4",
                    boxShadow: 0,
                }}
            >
                <CardContent>
                    <div className="PaymentName-icon">
                        {" "}
                        <Typography sx={{fontSize: "14px", color: "#9470FD"}} color="text.secondary" gutterBottom>
                            Total
                        </Typography>{" "}
                        &nbsp;
                        <div className="PaymentName-iconcheck">
                            {" "}
                            <img src={miniCorrect} alt={miniCorrect}/>{" "}
                        </div>
                        {" "}
                    </div>

                    <Typography
                        sx={{fontSize: "30px", fontWeight: "bold", color: "#9470FD"}}
                        className="grid-p"
                        color="text.secondary"
                    >
                        {total}
                    </Typography>
                </CardContent>
            </Card>

            <div className="Payment-vertical-line"/>
            <Card className="PaymentName-card"
                  sx={{
                    mr: 0.5,
                    ml: 0.5,
                    display: "flex",
                    borderRadius: 0,
                    width: "240px",
                    bgcolor: "#F4F4F4",
                    boxShadow: 0,
                }}
            >
                <CardContent>
                    <div className="PaymentName-icon">
                        {" "}
                        <Typography sx={{fontSize: "14px", color: "#344767"}}
                                    color="text.secondary"
                                    gutterBottom
                        >
                            Amount Paid
                        </Typography>{" "}
                        &nbsp;
                        <div className="PaymentName-iconcheck">
                            {" "}
                            <img src={pending} alt={pending}/>{" "}
                        </div>
                        {" "}
                    </div>

                    <Typography
                        sx={{fontSize: "30px", fontWeight: "bold", color: "#344767"}}
                        className="grid-p"
                        color="text.secondary"
                    >
                        {amountPaid}
                    </Typography>
                </CardContent>
            </Card>
            <div className="Payment-vertical-line2"/>

            <Card
                className="PaymentName-card"
                sx={{
                    mr: 0.5,
                    ml: 0.5,
                    display: "flex",
                    borderRadius: 0,
                    width: "240px",
                    bgcolor: "#F4F4F4",
                    boxShadow: 0
                }}>
                <CardContent>
                    <div className="PaymentName-icon">
                        {" "}
                        <Typography sx={{fontSize: "14px", color: "#FF5733"}} color="text.secondary" gutterBottom>
                            Amount Due
                        </Typography>{" "}
                        &nbsp;
                        <div className="PaymentName-iconcheck">
                            {" "}
                            <img src={pending} alt={pending}/>{" "}
                        </div>
                        {" "}
                    </div>

                    <Typography
                        sx={{fontSize: "30px", fontWeight: "bold", color: "#FF5733"}}
                        className="grid-p"
                        color="text.secondary"
                    >
                        {amountDue}
                    </Typography>
                </CardContent>
            </Card>
        </div>
    );
}
