import Grid from "@mui/material/Grid";
import MDBox from "common/components/MDBox";
import MDButton from "common/components/MDButton";
import Icon from "@mui/material/Icon";
import TableComponent from "common/components/table";
import React, {useEffect, useState} from "react";
import Switch from "@mui/material/Switch";
import AddEventModel from "component/AddEventModel";
import MobileList from "common/components/mobileList/MobileList";
import {deleteEvent, getEventList} from "../../services/event/event";
import {useDispatch,useSelector} from "react-redux";
import Swal from "sweetalert2";
import "./style.css";
import {getImageUrl} from "../../common/utils/CommonFunctions";
import {defaultLoad} from "../../config/images";

const columns = [
    { field: 'title', headerName: 'Title',accessor: "title" },
    { field: 'image', headerName: 'Image',accessor: "image" },
    { field: 'tag', headerName: 'Tag',accessor: "tag" },
    {field: 'content', headerName: 'Content', accessor: "content"},
    { field: 'location', headerName: 'Location',accessor: "location" },
    { field: 'date', headerName: 'Date',accessor: "date" },
    { field: 'status', headerName: 'Status',accessor: "status" },
    { field: 'action', headerName: 'Action',accessor: "action"}
];

const Toast = Swal.mixin({
    toast: true,
    position: 'top-right',
    showConfirmButton: false,
    timer: 2000,
    onOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
});

function Event() {
    const dispatch = useDispatch();
    const [popUp, setPop] = useState(false);
    const [updatePopUp,setUpdatePopUp] = useState(false);
    const [selectedEvent, setSelectedEvent]=useState({});
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    const [tableData, setTableData] = useState([]);
    const [deleteLoader,setDeleteLoader] = useState();
    const event = useSelector(state => state.EventSlice.event);
    const deleteSlice = useSelector(state => state.DeleteEventSlice.event);

    const eventDelete = (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: " Do you want delete this event ?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
        }).then((result) => {
            if (result.isConfirmed ) {
                setDeleteLoader(true)
                dispatch(deleteEvent(id));
            }
        });
    };

    function getWindowDimensions() {
        const {
             innerWidth: width,
             innerHeight: height
        } = window;
        return {
             width,
             height
        };
    }

    const updateEvent=(data)=>{
        setUpdatePopUp(true);
        setSelectedEvent(data);
    }

    useEffect(() => {
        if (deleteLoader){
            setDeleteLoader(false)
            if (deleteSlice.isSuccess){
                dispatch(getEventList())
                Toast.fire({
                    icon: 'success',
                    title: 'Event Deleted Successfully'
                });
            } else if (deleteSlice.isError) {
                Toast.fire({
                    icon: 'error',
                    title: 'Failed to delete Event'
                });
            }
        }
    }, [deleteSlice.data]);

    useEffect(() => {
        dispatch(getEventList());
    }, []);

    useEffect(() => {
        if(event.isSuccess){
            const data = event.data?.data;
            const array = []
            data.forEach((val)=> {
                array.push(
                    {
                        id:val.id,
                        title: val.title,
                        image: <img width={70} src={getImageUrl(val.image)} alt={val.image} onError={(e) => {
                            e.target.src = defaultLoad;
                        }}/>,
                        content:val.event_content,
                        tag: val.tag,
                        location: val.location,
                        date: val.date,
                        status: <Switch checked={val.status==="active"}  color="secondary" />,
                        action:
                            (<div className="my-class1">
                                {windowDimensions.width < 400 || windowDimensions.width > 768 ?
                                    <MDButton size="large" variant="gradient" color="success" iconOnly={true}
                                              sx={{mr:1}}
                                              onClick={() => updateEvent(val)}>
                                        <Icon fontSize="large">edit</Icon>
                                    </MDButton>:
                                    <MDButton variant="gradient" color="success"
                                              onClick={() => updateEvent(val)}>
                                        <Icon fontSize="large">edit</Icon>
                                        Edit
                                    </MDButton>
                                }
                                {windowDimensions.width < 400 || windowDimensions.width > 768 ?
                                    <MDButton size="large" variant="gradient" color="error" iconOnly={true} onClick={() => eventDelete(val.id)}>
                                        <Icon fontSize="large">delete</Icon>
                                    </MDButton>
                                    :
                                    <MDButton variant="gradient" color="error" className="button" onClick={() => eventDelete(val.id)}>
                                        <Icon fontSize="large">delete</Icon>
                                        Delete
                                    </MDButton>
                                }
                            </div>)
                    },
                )
            })
            setTableData(array);
        }
    }, [event.data]);

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }
        {
            window.addEventListener('resize', handleResize);
            return () => window.removeEventListener('resize', handleResize);
        }
    },[windowDimensions]);

    return (
        <MDBox pt={6} pb={3} >
            <div className="content">
                <Grid container spacing={6} justifyContent="flex-end">
                    <Grid item>
                        <MDButton variant="gradient"
                                  color="info"
                                  onClick={() =>{setPop(true)}}>
                            <Icon fontSize="large" sx={{marginRight:1,marginBottom:0.2}}>
                                control_point
                            </Icon>
                            Add New Event
                        </MDButton>
                    </Grid>
                </Grid>
                <Grid item xs={12} lg={12} md={12} sm={12} xl={12} pt={6}>
                    {windowDimensions.width > 830 ?
                        <TableComponent data={tableData} columns={columns} />
                        :
                        <MobileList COLUMNS={columns} dataJson={tableData} searchKey={['title', 'tag']}/>
                    }
                </Grid>
            </div>
            {popUp &&
                <AddEventModel setShowModal={setPop} showModal={popUp}/>
            }
            {updatePopUp &&
                <AddEventModel setShowModal={setUpdatePopUp} showModal={updatePopUp} updateData={true} assignData={selectedEvent} />
            }
        </MDBox>
    );
}
export default Event;
