import React from 'react';
import "./style.css";

export default function PopperAlert({top}) {
  return (
    <div className='popper' style={{top:`${top}`}}>
        Copied!
    </div>
  )
}
