import { createSlice } from "@reduxjs/toolkit";
import { updateStudent } from "services/student/student";

const initialState = {
    student : {
        data: [],
        isLoading: false,
        isSuccess: false,
        errorMessage: ''
    }
}

export const updateStudentSlice = createSlice({
    name: 'updateStudent',
    initialState,
    extraReducers: {
        [updateStudent.pending]: (state) => {
            state.student.isLoading = true;
        },
        [updateStudent.fulfilled]: (state, { payload }) => {
            state.student.isLoading = false;
            state.student.isSuccess = true;
            state.student.data = payload;
        },
        [updateStudent.rejected]: (state, { payload }) => {
            state.student.isLoading = false;
            state.student.isSuccess = false;
            state.student.errorMessage = payload
        }
    }
})
export default updateStudentSlice.reducer;