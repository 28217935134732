import { createSlice } from "@reduxjs/toolkit";
import { updateBlogs } from "services/blog/blog";

const initialState = {
    blog : {
        data: [],
        isLoading: false,
        isSuccess: false,
        errorMessage: ''
    }
}

export const updateBlogSlice = createSlice({
    name: 'updateBlog',
    initialState,
    extraReducers: {
        [updateBlogs.pending]: (state) => {
            state.blog.isLoading = true;
        },
        [updateBlogs.fulfilled]: (state, { payload }) => {
            state.blog.isLoading = false;
            state.blog.isSuccess = true;
            state.blog.data = payload;
        },
        [updateBlogs.rejected]: (state, { payload }) => {
            state.blog.isLoading = false;
            state.blog.isSuccess = false;
            state.blog.errorMessage = payload
        }
    }
})

export default updateBlogSlice.reducer;
