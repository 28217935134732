import React from "react";
import "./style.css";
import { Dialog } from "@material-ui/core";
import Icon from "@mui/material/Icon";

export default function ModalComponent({
  children,
  showModal = false,
  setShowModal,
  maxWidth,
}) {
  const closeModel = () => {
        setShowModal(false)
  };

  return (
    <Dialog
      open={showModal}
      maxWidth={maxWidth}
      onClose={closeModel}
      disableEnforceFocus
    >
      <Icon
        className="model-icon-button"
        fontSize="large"
        onClick={closeModel}
      >
        close
      </Icon>
      {children}
      
    </Dialog>
  );
}
