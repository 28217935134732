import Grid from "@mui/material/Grid";
import MDBox from "../MDBox";
import React from "react";
import './style.css'
import Icon from "@mui/material/Icon";

export default function CourseAssignCard({ name, state , index, selectedCourse }) {

    const handleEditClick = () => {
        selectedCourse(index);
    };
    return (
        <MDBox>
            <Grid container className="course-assign-card">
                <Grid item>
                    <p
                        className={`${state ?  "is-selected" : "not-select" }`}
                        onClick={handleEditClick}
                    >
                        {name}
                    </p>
                </Grid>
                <Grid item>
                    {state ? (
                        <Icon
                            fontSize="medium"
                            className="set-assign-course"
                        >
                            checkmark
                        </Icon>
                    ) : (
                        <Icon
                            fontSize="medium"
                            className="cancel-assign-course"
                            onClick={handleEditClick}
                        >
                            close
                        </Icon>
                    )}
                </Grid>
            </Grid>
        </MDBox>
    );
}