import {createSlice} from "@reduxjs/toolkit";
import {deleteReview} from "services/review/review";


const initialState = {
    review : {
        data : [],
        isLoading: false,
        isSuccess: false,
        errorMessage: ''
    }
}

export const deleteReviewSlice = createSlice({
    name: 'deleteReview',
    initialState,
    extraReducers:{
        [deleteReview.pending]:(state) =>{
            state.review.isLoading = true
            state.review.errorMessage = '';
        },
        [deleteReview.fulfilled]: (state, {payload}) =>{
            state.review.isSuccess = true;
            state.review.isLoading = false;
            state.review.data = payload;
        },
        [deleteReview.rejected]: (state, {payload}) =>{
            state.review.isSuccess = false;
            state.review.isLoading = false;
            state.review.errorMessage = payload;
        }
    }
})

export default deleteReviewSlice.reducer;