import ModalComponent from "common/components/Model";
import MDBox from "common/components/MDBox";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import React, {useEffect, useState} from "react";
import ImageUploader from "common/components/ImageUploader";
import "./style.css";
import MDButton from "common/components/MDButton";
import Icon from "@mui/material/Icon";
import CommonSelect from "common/components/CustomSelect";
import {useDispatch, useSelector} from "react-redux";
import {createReview, getReviewList, updateReviewData} from "services/review/review";
import Switch from "@mui/material/Switch";
import Swal from "sweetalert2";
import {getImageUrl, onImageEdit} from "common/utils/CommonFunctions";


const Toast = Swal.mixin({
    toast: true, position: "top", showConfirmButton: false, zIndex: 10, timer: 2000, onOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
});

const AddReviewModel = ({setShowModal, showModal, updateData, assignData}) => {

    const [imageUrl, setImageUrl] = useState(updateData ? getImageUrl(assignData.image) : "");
    const [name, setName] = useState(updateData ? assignData?.name : "")
    const [course, setCourse] = useState(updateData ? assignData?.course : "")
    const [position, setPosition] = useState(updateData ? assignData?.position : "")
    const [title, setTitle] = useState(updateData ? assignData?.title : "")
    const [status, setStatus] = useState(updateData ? assignData?.status : "active")
    const [review, setReview] = useState(updateData ? assignData?.review : "")
    const [company, setCompany] = useState(updateData ? assignData?.company : "")
    const [facebook, setFacebook] = useState(updateData ? (assignData?.facebook === null? "":assignData.facebook) : "")
    const [youtube, setYoutube] = useState(updateData ? (assignData?.youtube === null? "":assignData.youtube) : "")
    const [linkedin, setLinkedin] = useState(updateData ? (assignData?.linkedin === null? "":assignData.linkedin) : "")
    const [isValidImage, setIsValidImage] = useState(true);
    const [postImage, setPostImage] = useState();
    const [courseList, setCourseList] = useState([]);
    const [loader, setLoader] = useState(false);
    const dispatch = useDispatch();
    const addReview = useSelector((state) => state.addReviewSlice.review);
    const updateReview = useSelector((state) => state.updateReviewSlice.review);
    const courses = useSelector((state) => state.courseSlice.course);


    const clearText = () => {
        setTitle('')
        setName('')
        setCourse('')
        setPosition('')
        setReview('')
        setCompany('')
        setFacebook('')
        setYoutube('')
        setLinkedin('')
        setImageUrl('')
    }

    const handlePostImage = (val) => {
        const img = new Image();
        img.onload = function () {
            if (img.width === 160 && img.height === 190) {
                setIsValidImage(true);
                setPostImage(val);
            } else {
                setIsValidImage(false);
            }
        };
        img.src = URL.createObjectURL(val);
    };

    useEffect(() => {
        if (updateData) {
            onImageEdit(
                getImageUrl(assignData.image)
            ).then((file) => setPostImage(file));
        }
    }, []);



    useEffect(() => {
        if (courses.isSuccess) {
            const data = courses.data?.data
            const array = [];
            data.forEach((course) => {
                array.push({
                    label: course.title, value: course.title
                })
            })
            setCourseList(array)
        }
    }, [courses.data])
    const postReview = () => {
        const form = new FormData();
        form.append("name", name);
        form.append("course", course);
        form.append("image", postImage);
        form.append("position", position);
        form.append("company", company);
        form.append("facebook", facebook);
        form.append("youtube", youtube);
        form.append("linkedin", linkedin);
        form.append("title", title);
        form.append("review", review);
        form.append("status", status);

        updateData ? dispatch(updateReviewData({page: form, id: assignData.id})) : dispatch(createReview(form));
        setLoader(true);
    }

    useEffect(() => {
        if (loader) {
            setLoader(false);
            if ((addReview.isSuccess && !addReview.isLoading) || (updateReview.isSuccess && !updateReview.isLoading)) {
                setShowModal(false);
                dispatch(getReviewList());
                Toast.fire({
                    icon: "success", title: updateData ? "Review Updated Successfully" : "Review Added Successfully",
                });
            } else if (addReview.isError) {
                setShowModal(false);
                Toast.fire({
                    icon: "error", title: "Invalid Input",
                });
            }
        }
    }, [addReview.data, updateReview.data]);

    const handleCourseChange = (e) => {
        setCourse(e.target.value)
    }

    const copyLink = (text) => {
        navigator.clipboard.writeText(text);
    };

    return (<ModalComponent
        setShowModal={setShowModal}
        showModal={showModal}
        maxWidth={"md"}
    >
        <MDBox
            pb={3}
            sx={{
                padding: {
                    xs: 2, md: 6,
                }, paddingTop: {
                    xs: 6,
                },
            }}
        >
            <h4 className="heading">{updateData ? "Update Review" : "Add New Review"}</h4>
            <Grid container spacing={2}>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                            <Grid container spacing>
                                <Grid item xl={9} lg={9} md={9} sm={12} xs={12}>
                                    <TextField
                                        id="outlined-helperText"
                                        label="Name"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                        fullWidth
                                    />
                                </Grid>
                                {updateData && <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
                                    Status <Switch checked={status === "active"} color="secondary"
                                                   onChange={(e) => {
                                                       const newStatus = e.target.checked ? "active" : "inactive";
                                                       setStatus(newStatus);
                                                   }}/>
                                </Grid>}
                            </Grid>

                        </Grid>
                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                            <CommonSelect
                                id="outlined-helperText"
                                options={courseList}
                                label="Course"
                                value={course}
                                onChange={handleCourseChange}
                            />
                        </Grid>

                    </Grid>
                </Grid>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <hr/>
                </Grid>

                {/*image uploder section*/}
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xl={5} lg={5} md={5} sm={12} xs={12} className="imgUploder">
                            <Grid container>
                                <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="imgUploder">
                                    <ImageUploader
                                        postImage={handlePostImage}
                                        onImageSelect={setImageUrl}
                                        imageUrl={imageUrl}
                                        width="250px"
                                        height="250px">
                                    </ImageUploader>
                                </Grid>
                                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                    {!isValidImage && (
                                        <div className="image-upload invalid-image imgUploder">You are added invalid image</div>)}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xl={7} lg={7} md={7} sm={12} xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                    <TextField
                                        id="outlined-helperText"
                                        label="Position"
                                        value={position}
                                        onChange={(e) => setPosition(e.target.value)}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                                    <TextField
                                        id="outlined-helperText"
                                        label="Company or University"
                                        value={company}
                                        onChange={(e) => setCompany(e.target.value)}
                                        fullWidth
                                    />
                                </Grid>

                                {/*facebook link*/}
                                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                    <Grid container spacing={2}>
                                        <Grid item xl={1} lg={1} md={1} sm={1} xs={1}
                                              className="socialIconContainer" sx={{mt: 0.2}}>
                                            <div>
                                                <img className="social_icon"
                                                     src={"https://www.facebook.com/images/fb_icon_325x325.png"} alt={"Facebook"}/>
                                            </div>
                                        </Grid>
                                        <Grid item xl={11} lg={11} md={11} sm={11} xs={11}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} md={12} xl={12}>
                                                    <TextField
                                                        id="outlined-helperText"
                                                        label="Facebook"
                                                        value={facebook}
                                                        onChange={(e) => setFacebook(e.target.value)}
                                                        fullWidth
                                                        InputProps={{
                                                            endAdornment: (<MDButton variant="contained" iconOnly={true}
                                                                                     onClick={() => copyLink(facebook)}
                                                            >
                                                                <Icon fontSize="large">
                                                                    copy
                                                                </Icon>
                                                            </MDButton>),
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                {/*youtube link*/}
                                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                    <Grid container spacing={2}>
                                        <Grid item xl={1} lg={1} md={1} sm={1} xs={1}
                                              className="socialIconContainer" sx={{mt: 0.2}}>
                                            <div>
                                                <img className="social_icon"
                                                     src={"https://upload.wikimedia.org/wikipedia/commons/thumb/0/09/YouTube_full-color_icon_%282017%29.svg/2560px-YouTube_full-color_icon_%282017%29.svg.png"} alt={"youtube"}/>
                                            </div>
                                        </Grid>
                                        <Grid item xl={11} lg={11} md={11} sm={11} xs={11}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} md={12} xl={12}>
                                                    <TextField
                                                        id="outlined-helperText"
                                                        label="Youtube"
                                                        value={youtube}
                                                        onChange={(e) => setYoutube(e.target.value)}
                                                        fullWidth
                                                        InputProps={{
                                                            endAdornment: (<MDButton variant="contained" iconOnly={true}
                                                                                     onClick={() => copyLink(youtube)}
                                                            >
                                                                <Icon fontSize="large">
                                                                    copy
                                                                </Icon>
                                                            </MDButton>),
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                {/*linkedin link*/}
                                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                    <Grid container spacing={2}>
                                        <Grid item xl={1} lg={1} md={1} sm={1} xs={1}
                                              className="socialIconContainer" sx={{mt: 0.2}}>
                                            <div>
                                                <img className="social_icon"
                                                     src={"https://static-00.iconduck.com/assets.00/linkedin-icon-2048x2048-ya5g47j2.png"} alt={"linkedin"}/>
                                            </div>
                                        </Grid>
                                        <Grid item xl={11} lg={11} md={11} sm={11} xs={11}>
                                            <Grid container spacing={2}>
                                                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                                    <TextField
                                                        id="outlined-helperText"
                                                        label="Linkedin"
                                                        value={linkedin}
                                                        onChange={(e) => setLinkedin(e.target.value)}
                                                        fullWidth
                                                        InputProps={{
                                                            endAdornment: (<MDButton variant="contained" iconOnly={true}
                                                                                     onClick={() => copyLink(linkedin)}
                                                            >
                                                                <Icon fontSize="large">
                                                                    copy
                                                                </Icon>
                                                            </MDButton>),
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                {/*review and tilte*/}
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                            <TextField
                                id="outlined-helperText"
                                label="Title"
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                            <TextField
                                id="outlined-helperText"
                                label="Review"
                                value={review}
                                onChange={(e) => setReview(e.target.value)}
                                fullWidth
                                multiline
                                rows={4}
                            />
                        </Grid>
                    </Grid>
                </Grid>

                {/*Button section*/}
                <Grid item xs={12}>
                    <Grid container spacing={2} className="btnContainer">
                        <Grid item xs={12} sm={6} md={3} xl={3}>
                            {updateData ? ("") : (<MDButton
                                variant="outlined"
                                color="secondary"
                                disabled={updateData}
                                sx={{
                                    width: {
                                        xs: "100%", md: "100%",
                                    },
                                }}
                                onClick={() => {
                                    clearText();
                                }}
                            >
                                Clear
                            </MDButton>)}
                        </Grid>
                        <Grid item xs={12} sm={6} md={3} xl={3}>
                            <MDButton
                                variant="gradient"
                                color={"success"}
                                disabled={
                                    name === "" ||
                                    course === "" ||
                                    position === "" ||
                                    title === "" ||
                                    review === "" ||
                                    !imageUrl ||
                                    !isValidImage ||
                                    addReview.isLoading ||
                                    updateReview.isLoading
                                }
                                sx={{
                                    width: {
                                        xs: "100%", md: "106%",
                                    },
                                }}
                                onClick={postReview}
                            >
                                {updateData ? "Update Review" : "Add Review"}
                            </MDButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </MDBox>
    </ModalComponent>)
}

export default AddReviewModel;
