import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "app/App";
import { MaterialUIControllerProvider } from "context";
import "./style.css"
import { Provider } from 'react-redux'
import { store } from './store';

ReactDOM.render(
    <Provider store={store}>
      <BrowserRouter>
        <MaterialUIControllerProvider>
          <App />
        </MaterialUIControllerProvider>
      </BrowserRouter>
    </Provider>,
  document.getElementById("root")
);
