import { createSlice } from "@reduxjs/toolkit";
import { deleteCourse } from "services/course/course";

const initialState = {
    course : {
        data: [],
        isLoading: false,
        isSuccess: false,
        errorMessage: ''
    }
}
export const deleteCourseSlice = createSlice({
    name: 'deleteBlog',
    initialState,
    extraReducers: {
        [deleteCourse.pending]: (state) => {
            state.course.isLoading = true;
        },
        [deleteCourse.fulfilled]: (state, { payload }) => {
            state.course.isLoading = false;
            state.course.isSuccess = true;
            state.course.data = payload;
        },
        [deleteCourse.rejected]: (state, { payload }) => {
            state.course.isLoading = false;
            state.course.isSuccess = false;
            state.user.errorMessage = payload
        }
    }
})

export default deleteCourseSlice.reducer;
