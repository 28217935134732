import { createSlice } from "@reduxjs/toolkit";
import { getCourseList } from "services/course/course";

const initialState = {
    course : {
        data: [],
        isLoading: false,
        isSuccess: false,
        errorMessage: ''
    }
}

export const courseSlice = createSlice({
    name: 'course',
    initialState,
    extraReducers: {
        [getCourseList.pending]: (state) => {
            state.course.isLoading = true;
        },
        [getCourseList.fulfilled]: (state, { payload }) => {
            state.course.isLoading = false;
            state.course.isSuccess = true;
            state.course.data = payload;
        },
        [getCourseList.rejected]: (state, { payload }) => {
            state.course.isLoading = false;
            state.course.isSuccess = false;
            state.course.errorMessage = payload
        }
    }
})
export default courseSlice.reducer;