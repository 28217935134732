import { createSlice } from "@reduxjs/toolkit";
import { deleteStudent } from "services/student/student";

const initialState = {
    student : {
        data: [],
        isLoading: false,
        isSuccess: false,
        errorMessage: ''
    }
}
export const deleteStudentSlice = createSlice({
    name: 'deleteStudent',
    initialState,
    extraReducers: {
        [deleteStudent.pending]: (state) => {
            state.student.isLoading = true;
        },
        [deleteStudent.fulfilled]: (state, { payload }) => {
            state.student.isLoading = false;
            state.student.isSuccess = true;
            state.student.data = payload;
        },
        [deleteStudent.rejected]: (state, { payload }) => {
            state.student.isLoading = false;
            state.student.isSuccess = false;
            state.user.errorMessage = payload
        }
    }
})

export default deleteStudentSlice.reducer;
