import { createSlice } from "@reduxjs/toolkit";
import { deleteIntake } from "services/intake/intake";

const initialState = {
    intake : {
        data: [],
        isLoading: false,
        isSuccess: false,
        errorMessage: ''
    }
}
export const deleteIntakeSlice = createSlice({
    name: 'deleteIntake',
    initialState,
    extraReducers: {
        [deleteIntake.pending]: (state) => {
            state.intake.isLoading = true;
        },
        [deleteIntake.fulfilled]: (state, { payload }) => {
            state.intake.isLoading = false;
            state.intake.isSuccess = true;
            state.intake.data = payload;
        },
        [deleteIntake.rejected]: (state, { payload }) => {
            state.intake.isLoading = false;
            state.intake.isSuccess = false;
            state.user.errorMessage = payload
        }
    }
})

export default deleteIntakeSlice.reducer;