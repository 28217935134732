import { createSlice } from "@reduxjs/toolkit";
import { postBlog } from "services/blog/blog";

const initialState = {
    blog : {
        data: [],
        isLoading: false,
        isSuccess: false,
        errorMessage: ''
    }
}
export const saveBlogSlice = createSlice({
    name: 'saveBlog',
    initialState,
    extraReducers: {
        [postBlog.pending]: (state) => {
            state.blog.isLoading = true;
        },
        [postBlog.fulfilled]: (state, { payload }) => {
            state.blog.isLoading = false;
            state.blog.isSuccess = true;
            state.blog.data = payload;
        },
        [postBlog.rejected]: (state, { payload }) => {
            state.blog.isLoading = false;
            state.blog.isSuccess = false;
            state.blog.errorMessage = payload
        }
    }
})
export default saveBlogSlice.reducer;