import Dashboard from "pages/dashboard";
import Blog from "pages/blog";
import Event from "pages/event";
import Student from "pages/student";
import Course from "pages/course-intake";
import StudentDetails from "pages/studentDetails";
import Payment from "pages/Payment";
import Acpt from "pages/acpt";
import Review from "pages/review";
import DashboardIcon from '@mui/icons-material/Dashboard';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import EventIcon from '@mui/icons-material/Event';
import SchoolIcon from '@mui/icons-material/School';
import FaceIcon from '@mui/icons-material/Face';
import GridViewIcon from '@mui/icons-material/GridView';
import Reviews from "@mui/icons-material/Reviews";

const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <DashboardIcon/>,
    route: "/dashboard",
    component: <Dashboard/>,
  },
  {
    type: "collapse",
    name: "Manage Blog",
    key: "blogs",
    icon: <BookmarkIcon/>,
    route: "/blogs",
    component: <Blog />,
  },
  {
    type: "collapse",
    name: "Manage Event",
    key: "event",
    icon: <EventIcon/>,
    route: "/event",
    component: <Event/>,
  },
  {
    type: "collapse",
    name: "Manage Course",
    key: "course",
    icon: <SchoolIcon/>,
    route: "/course",
    component: <Course/>,
  },
  {
    type: "collapse",
    name: "Manage Student",
    key: "student",
    icon: <FaceIcon/>,
    route: "/student",
    component: <Student/>,
  },
  {
    type: "private",
    name: "Student Details",
    key: "details",
    icon: <FaceIcon/>,
    route: "/student-details/:id",
    component: <StudentDetails />,
  },
  {
    type: "private",
    name: "Payment",
    key: "payment",
    icon: <FaceIcon/>,
    route: "/payment/:id",
    component: <Payment/>,
  },
  {
    type: "collapse",
    name: "Manage Review",
    key: "review",
    icon: <Reviews/>,
    route: "/review",
    component: <Review/>,
  },
  {
    type: "collapse",
    name: "ACPT Dashboard",
    key: "acpt-dashboard",
    icon: <GridViewIcon/>,
    route: "/acpt-dashboard",
    component: <Acpt/>,
  },

];

export default routes;
