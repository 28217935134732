// blog details
import BlogSlice from 'store/slice/blog/blogSlice';
import SaveBlogSlice from 'store/slice/blog/saveBlogSlice';
import DeleteBlogSlice from "store/slice/blog/deleteBlogSlice";
import UpdateBlogSlice from "store/slice/blog/updateBlogSlice";

//intake details
import intakeSlice from "store/slice/intake/intakeSlice";
import saveIntakeSlice from "store/slice/intake/saveIntakeSlice";
import deleteIntakeSlice from "store/slice/intake/deleteIntakeSlice";
import updateIntakeSlice from "store/slice/intake/updateIntakeSlice";

//Event details
import EventSlice from "store/slice/event/eventSlice";
import SaveEventSlice from "store/slice/event/saveEventSlice";
import DeleteEventSlice from "store/slice/event/deleteEventSlice";
import UpdateEventSlice from "store/slice/event/updateEventSlice";

//Course details
import courseSlice from "store/slice/course/courseSlice"
import saveCourseSlice from "store/slice/course/saveCourseSlice"
import UpdateCourseSlice from "store/slice/course/updateCourseSlice";
import deleteCourseSlice from "store/slice/course/deleteCourseSlice";

//student details
import studentSlice from "store/slice/student/studentSlice"
import saveStudentSlice from "store/slice/student/saveStudentSlice"
import deleteStudentSlice from "store/slice/student/deleteStudentSlice";
import getStudentDetailsSlice from "store/slice/student/getStudentDetailsSlice";
import UpdateStudentSlice from "store/slice/student/updateStudentSlice";
import getCourseDetailsSlice from "store/slice/student/getCourseDetailsSlice";
import registerCourseSlice from "store/slice/student/registerCourseSlice";
import deleteRegistrationSlice from "store/slice/student/deleteRegistrationSlice";
import completeStatusSlice from "store/slice/student/completeStatusSlice"
import allDetailIntakeSlice from "store/slice/intake/allDetailIntakeSlice";
import studentCountSlice from "store/slice/student/studentCountSlice";
import getStudentCertificateSlice from 'store/slice/student/getStudentCertificateSlice';


//payment
import getAllPaymentSlice from 'store/slice/payment/getAllPaymentSlice';
import addPayPaymentSlice from 'store/slice/payment/addPayPaymentSlice';
import getPaymentReceiptSlice  from 'store/slice/payment/getPayReceipt';
import postEmailSlice from 'store/slice/payment/postEmail';


//certificate
import  postCertificateSlice  from 'store/slice/student/postCertificate';
import updateRegisteredCourseSlice from "store/slice/student/updateRegisteredCourseSlice";

//review
import addReviewSlice from "store/slice/review/addReviewSlice";
import getReviewListSlice from "store/slice/review/getReviewListSlice";
import updateReviewSlice from "store/slice/review/updateReviewSlice";
import deleteReviewSlice from "store/slice/review/deleteReviewSlice";

const rootReducer = {
    BlogSlice,
    SaveBlogSlice,
    intakeSlice,
    deleteIntakeSlice,
    saveIntakeSlice,
    updateIntakeSlice,
    EventSlice,
    SaveEventSlice,
    DeleteEventSlice,
    UpdateEventSlice,
    DeleteBlogSlice,
    UpdateBlogSlice,
    courseSlice,
    saveCourseSlice,
    UpdateCourseSlice,
    deleteCourseSlice,
    studentSlice,
    saveStudentSlice,
    deleteStudentSlice,
    getStudentDetailsSlice,
    UpdateStudentSlice,
    getCourseDetailsSlice,
    registerCourseSlice,
    deleteRegistrationSlice,
    completeStatusSlice,
    allDetailIntakeSlice,
    studentCountSlice,
    getStudentCertificateSlice,
    getAllPaymentSlice,
    addPayPaymentSlice,
    getPaymentReceiptSlice,
    postEmailSlice,
    postCertificateSlice,
    updateRegisteredCourseSlice,
    addReviewSlice,
    getReviewListSlice,
    updateReviewSlice,
    deleteReviewSlice,
}
export default rootReducer;