import React, {useEffect, useState} from 'react';
import Grid from "@mui/material/Grid";
import MDBox from "common/components/MDBox";
import MDButton from "common/components/MDButton";
import Icon from "@mui/material/Icon";
import TableComponent from 'common/components/table';
import Switch from "@mui/material/Switch";
import AddBlogModel from "component/AddBlogModel";
import MobileList from "common/components/mobileList/MobileList";
import "./style.css"
import {getBLogList,deleteBlog} from "services/blog/blog";
import {useDispatch, useSelector} from "react-redux";
import Swal from "sweetalert2";
import { getImageUrl } from "common/utils/CommonFunctions";
import { defaultLoad } from 'config/images';
const Toast = Swal.mixin({
    toast: true,
    position: 'top',
    showConfirmButton: false,
    timer: 2000,
    onOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
});

const columns = [
    { field: 'title', headerName: 'Title',accessor: "title" },
    { field: 'image', headerName: 'Image',accessor: "image" },
    { field: 'tag', headerName: 'Tag',accessor: "tag" },
    { field: 'content', headerName: 'Content',accessor: "content" },
    { field: 'date', headerName: 'Date',accessor: "date" },
    { field: 'status', headerName: 'Status' ,accessor: "status"},
    { field: 'action', headerName: 'Action',accessor: "action" },
];

function Blog() {
    const dispatch = useDispatch()
    const [popUp, setPop] = useState(false);
    const [deleteLoader, setDeleteLoader] = useState(false);
    const [updateBlogModel,setUpdateBlogModel] = useState(false);
    const [selectedBlog,setSelectedBlog] = useState({});
    const [tableData, setTableData] = useState([])
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    const blog = useSelector(state => state.BlogSlice.blog);
    const blogDelete = useSelector(state => state.DeleteBlogSlice.blog);

    const updateBlog=(data)=>{
        setUpdateBlogModel(true);
        setSelectedBlog(data);
    }

    const handleDelete = (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: 'Do you want to delete this intake?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.isConfirmed ) {
                setDeleteLoader(true)
                dispatch(deleteBlog(id));
            }
        });
    };
    useEffect(() => {
        if (deleteLoader){
            setDeleteLoader(false)
            if (blogDelete.isSuccess){
                dispatch(getBLogList())
                Toast.fire({
                    icon: 'success',
                    title: 'Blog Deleted Successfully!'
                });
            } else if (blogDelete.isError) {
                Toast.fire({
                    icon: 'error',
                    title: 'Failed to Delete Blog'
                });
            }
        }
    }, [blogDelete.data]);

 useEffect(() => {
        dispatch(getBLogList());
    }, []);

    useEffect(() => {
        if(blog.isSuccess){
            const data = blog.data?.data;
            const array = []
            data.forEach((val)=> {
                array.push(
                    {
                        id: val.id,
                        title: val.title,
                        image: <img width={70} src={getImageUrl(val.image)} alt={val.image} onError={(e) => {
                            e.target.src = defaultLoad; 
                          }}/>,
                        tag: val.tag,
                        content: val.blog_content,
                        date: val.date,
                        status: <Switch checked={val.status==="active"} color="warning"/>,
                        action:
                            (
                                <div className="my-class1">
                                    <MDButton
                                        variant="gradient"
                                        color="success"
                                        onClick={()=> updateBlog(val)}
                                    >
                                        <Icon fontSize="medium">edit</Icon>
                                        {windowDimensions.width >= 400 && windowDimensions.width <= 768 && "Edit"}
                                    </MDButton>
                                    <MDButton variant="gradient" color="error" onClick={() => handleDelete(val.id)}>
                                        <Icon fontSize="medium">delete</Icon>
                                        {windowDimensions.width >= 400 && windowDimensions.width <= 768 && "Delete"}
                                    </MDButton>
                                </div>
                            ),
                    }
                )
            })
            setTableData(array);
        }
    }, [blog.data]);

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }
        {
            window.addEventListener('resize', handleResize);
            return () => window.removeEventListener('resize', handleResize);
        }
    },[windowDimensions]);

    function getWindowDimensions() {
        const {
            innerWidth: width,
            innerHeight: height
        } = window;
        return {
            width,
            height
        };
    }
    return (
        <MDBox pt={6} pb={3}>
            <div className="content">
                <Grid container spacing={6} justifyContent="flex-end">
                    <Grid item>
                        <MDButton variant="gradient" color="info" onClick={() => {
                            setPop(true)}} >
                            <Icon fontSize="large" sx={{marginRight:1,marginBottom:0.2}}
                            >
                                control_point
                            </Icon>
                            Add New Blog
                        </MDButton>
                    </Grid>
                </Grid>
                <Grid pt={6}>
                    {windowDimensions.width > 768 ?
                        <TableComponent data={tableData} columns={columns} />
                        :
                        <MobileList COLUMNS={columns} dataJson={tableData} searchKey={['title', 'tag']}/>
                    }
                </Grid>
            </div>
            {popUp &&
                <AddBlogModel setShowModal={()=>setPop(false)} showModal={popUp} />
            }
            {updateBlogModel &&
                 <AddBlogModel setShowModal={setUpdateBlogModel} showModal={updateBlogModel} updateData={true} assignData={selectedBlog}/>
            }
        </MDBox>
    );
}
export default Blog;
