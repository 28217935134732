import React from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import PopperAlert from "common/components/Popper/PopperAlert";
import { useEffect } from "react";

export default function CopyText({ val, copiedValue, setCopiedValue, top }) {
  useEffect(() => {
    if (copiedValue !== "") {
      setTimeout(() => setCopiedValue(""), 1000);
    }
  }, [copiedValue]);

  return (
    <div style={{
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    }}>
      {[
        copiedValue === val && copiedValue !== "" && <PopperAlert top={top} />,
        <CopyToClipboard text={val} onCopy={() => setCopiedValue(val)}>
          <span
            style={{
              display: 'block',
              cursor: "pointer",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {val}
          </span>
        </CopyToClipboard>,
      ]}
    </div>
  );
}
