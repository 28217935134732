import Grid from "@mui/material/Grid";
import MDBox from "../MDBox";
import React, { useEffect } from "react";
import './style.css'

export default function CourseCard({ name, image, imageWidth, state, selectedIntake,button, index }){

    const handleClick = () => {
        selectedIntake(index);
    };
    useEffect(() => {
        if (index === 0) {
            selectedIntake(index);
        }
    }, []);

    return(
        <MDBox>
            <Grid container className={`course-card ${state ? 'checked' : " "}`}
                  onClick={handleClick}
                  sx={{
                      gap:{
                          xs:2,
                          xl:0
                      }
                  }} >
                <Grid item xs={12} md={3} xl={3} container  alignItems="center" >
                    <img src={image} alt="" width={imageWidth}/>
                </Grid>
                <Grid item xs={12} md={6} lg={6} xl={7} className="course-name" container  alignItems="center">
                    {name}
                </Grid>
                <Grid item xs={12} md={1} xl={1} container alignItems="center"
                      sx={{
                          pl:{
                              md:2,
                              xl:2
                          }
                      }}
                >
                    {button}
                </Grid>
            </Grid>
        </MDBox>
    );
}