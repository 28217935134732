import { createSlice } from "@reduxjs/toolkit";
import { getAllIntakeList } from "services/intake/intake";

const initialState = {
    intake : {
        data: [],
        isLoading: false,
        isSuccess: false,
        errorMessage: ''
    }
}

export const allDetailIntakeSlice = createSlice({
    name: 'allIntake',
    initialState,
    extraReducers: {
        [getAllIntakeList.pending]: (state) => {
            state.intake.isLoading = true;
        },
        [getAllIntakeList.fulfilled]: (state, { payload }) => {
            state.intake.isLoading = false;
            state.intake.isSuccess = true;
            state.intake.data = payload;
        },
        [getAllIntakeList.rejected]: (state, { payload }) => {
            state.intake.isLoading = false;
            state.intake.isSuccess = false;
            state.intake.errorMessage = payload
        }
    }
})

export default allDetailIntakeSlice.reducer;