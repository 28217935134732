import React from "react";
import Grid from "@mui/material/Grid";
import "./style.css"

function DashBoardCards({item}){
    const props = item;

    return (
        <Grid item p={1} xs={12} sm={6} md={6} lg={3} xl={3} >
           <div className="card1" style={{backgroundImage:`linear-gradient(90deg,${props.bgColourmain} 0%,${props.bgColourSec} 99.93%)`}}>
               <div className="smallCard">
                   <img src={props.image} id="image"/>
               </div>
               <div>
                   <p id= "title100">{props.title}</p>
                   <h2 id="title200">{props.noOfStudents}</h2>
               </div>
           </div>
        </Grid>
    );
}
export default DashBoardCards;